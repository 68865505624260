import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { SampleRequest } from '../../models/sample-request';
import * as action_types from '../../actions/action-types';
import {ProxyAATeamMember}  from '../../models/team';
import {
  ajaxCallError,
  beginAjaxCall,
  AjaxCallActions
} from '../../actions/ajax-status.actions';

import config from '../../app-config'; 
import { StoreState } from '../../store/store-state';

interface GetSampleStandardsVerificationRequestsSuccessAction extends Action {
  type: action_types.GET_SAMPLE_STANDARDS_VERIFICATION_REQUESTS_SUCCESS;
  sampleRequests: SampleRequest[];
}
interface GetSampleMediaRequestsStartedAction extends Action {
  type: action_types.GET_SAMPLE_MEDIA_REQUESTS_STARTED;
  isGetSampleMediaRequestStarted: boolean;
}

interface GetSampleMediaRequestsStartedAction extends Action {
  type: action_types.GET_SAMPLE_MEDIA_REQUESTS_STARTED;
  isGetSampleMediaRequestStarted: boolean;
}

interface GetSampleMediaRequestsCompletedAction extends Action {
  type: action_types.GET_SAMPLE_MEDIA_REQUESTS_COMPLETED;
  isGetSampleMediaRequestCompleted: boolean;
}

interface UpdateSampleStandardsVerificationRequestsSuccessAction extends Action {
  type: action_types.UPDATE_SAMPLE_STANDARDS_VERIFICATION_REQUESTS_SUCCESS;
  sampleRequest: SampleRequest;
}

interface UpdateProxyAATeamMemberSuccessAction extends Action {
  type: action_types.UPDATE_PROXT_AA_TEAM_MEMBER;
  proxyAATeamMember: ProxyAATeamMember;
}


export type SampleStandardsVerificationRequestsAction =
  | GetSampleStandardsVerificationRequestsSuccessAction
  | UpdateSampleStandardsVerificationRequestsSuccessAction
  | UpdateProxyAATeamMemberSuccessAction
  | GetSampleMediaRequestsStartedAction
  | GetSampleMediaRequestsCompletedAction
  | AjaxCallActions;

type ThunkResult<R> = ThunkAction<
  R,
  StoreState,
  undefined,
  SampleStandardsVerificationRequestsAction
>;

export const getSampleStandardsVerificationRquestsSuccessAction = (
  sampleRequests: SampleRequest[]
): GetSampleStandardsVerificationRequestsSuccessAction => ({
  sampleRequests,
  type: action_types.GET_SAMPLE_STANDARDS_VERIFICATION_REQUESTS_SUCCESS
});

export const updateSampleStandardsVerificationRquestsSuccessAction = (
  sampleRequest: SampleRequest
): UpdateSampleStandardsVerificationRequestsSuccessAction => ({
  sampleRequest,
  type: action_types.UPDATE_SAMPLE_STANDARDS_VERIFICATION_REQUESTS_SUCCESS
});





export const updateProxyAATeamMemberAction = (
  proxyAATeamMember: ProxyAATeamMember
): UpdateProxyAATeamMemberSuccessAction => ({
  proxyAATeamMember,
  type: action_types.UPDATE_PROXT_AA_TEAM_MEMBER  
});

export const updateProxyAATeamMember = (
  proxyAATeamMember:ProxyAATeamMember
) : UpdateProxyAATeamMemberSuccessAction => {
  return  updateProxyAATeamMemberAction(proxyAATeamMember)
};


export const getSampleStandardsVerificationRequests = (
  assessmentAssociateId: string
): ThunkResult<Promise<void>> => {
  return dispatch => {
    dispatch(beginAjaxCall());
    dispatch(getSampleMediaRequestCompleted(false));
    dispatch(getSampleMediaRequestStarted(true));
    console.log("config.API_GATEWAY.URL : "+config.API_GATEWAY.URL);
    return axios
      .get(`${config.API_GATEWAY.URL}/sample-standards-verification-requests`, {        
        params: {
          assessmentAssociateId 
        }
      })
      .then((response: any) => {
        const sampleStandardsVerificationRequests =  response.data;  
        
        console.log("sampleStandardsVerificationRequests"+sampleStandardsVerificationRequests); 
             
        localStorage.setItem('sampleRequestCounts',sampleStandardsVerificationRequests.length)
        dispatch(
          getSampleStandardsVerificationRquestsSuccessAction(
            sampleStandardsVerificationRequests.map((sampleStandardsVerificationRequest: SampleRequest) => {
             
              const mr = new SampleRequest();
              mr.academicYear = sampleStandardsVerificationRequest.academicYear;
              mr.allocationId = sampleStandardsVerificationRequest.allocationId;
              mr.assessmentAssociateId = sampleStandardsVerificationRequest.assessmentAssociateId;
              mr.standardsVerifierName = sampleStandardsVerificationRequest.standardsVerifierName;
              mr.createdDateTime = sampleStandardsVerificationRequest.createdDateTime;
              mr.deadLineDateTime = sampleStandardsVerificationRequest.deadLineDateTime;
              mr.lastModifiedDateTime = sampleStandardsVerificationRequest.lastModifiedDateTime;
              mr.learningProviderId = sampleStandardsVerificationRequest.learningProviderId;
              mr.failureReason = sampleStandardsVerificationRequest.failureReason;
              mr.previousState = sampleStandardsVerificationRequest.previousState;
              mr.learningProviderName = sampleStandardsVerificationRequest.learningProviderName?sampleStandardsVerificationRequest.learningProviderName :'';
              mr.learningProviderCode = sampleStandardsVerificationRequest.learningProviderCode ?sampleStandardsVerificationRequest.learningProviderCode: '';
              mr.consortiaId = sampleStandardsVerificationRequest.consortiaId;
              mr.qualificationId = sampleStandardsVerificationRequest.qualificationId;
              mr.qualificationName = sampleStandardsVerificationRequest.qualificationName? sampleStandardsVerificationRequest.qualificationName :'';
              mr.qualificationGroupCode = sampleStandardsVerificationRequest.qualificationGroupCode?sampleStandardsVerificationRequest.qualificationGroupCode:'';
              mr.standardsVerificationSampleId = sampleStandardsVerificationRequest.standardsVerificationSampleId;
              mr.status =  sampleStandardsVerificationRequest.status; 
              mr.selectedUnits = sampleStandardsVerificationRequest.selectedUnits;
              mr.submittedUnits = sampleStandardsVerificationRequest.submittedUnits;
              mr.mediaSetId = sampleStandardsVerificationRequest.mediaSetId;
              mr.submittedBy = sampleStandardsVerificationRequest.submittedBy;
              mr.submissionDate = sampleStandardsVerificationRequest.submissionDate;
              mr.version = sampleStandardsVerificationRequest.version;
              mr.parentRequests = sampleStandardsVerificationRequest.parentRequests;
              mr.parentLearningProviderCode=sampleStandardsVerificationRequest.parentLearningProviderCode;
              return mr;
            })
          )
        );
        dispatch(getSampleMediaRequestCompleted(true));
      })
      .catch((error:any) => {
        console.log("Exception occured : " +error);
        dispatch(getSampleMediaRequestCompleted(true));
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const getSampleMediaRequestCompletedAction = (
  isGetSampleMediaRequestCompleted : boolean
): GetSampleMediaRequestsCompletedAction => ({
  isGetSampleMediaRequestCompleted,
  type: action_types.GET_SAMPLE_MEDIA_REQUESTS_COMPLETED  
});

export const getSampleMediaRequestCompleted = (
  isGetSampleMediaRequestCompleted : boolean
) : GetSampleMediaRequestsCompletedAction => {
  return getSampleMediaRequestCompletedAction(isGetSampleMediaRequestCompleted)
};

export const getSampleMediaRequestStartedAction = (
  isGetSampleMediaRequestStarted : boolean
): GetSampleMediaRequestsStartedAction => ({
  isGetSampleMediaRequestStarted,
  type: action_types.GET_SAMPLE_MEDIA_REQUESTS_STARTED  
});

export const getSampleMediaRequestStarted = (
  isGetSampleMediaRequestStarted : boolean
) : GetSampleMediaRequestsStartedAction => {
  return getSampleMediaRequestStartedAction(isGetSampleMediaRequestStarted)
};

export const refreshSampleSVRequest = (
  sampleRequest: SampleRequest
): ThunkResult<Promise<void>> => {
  return dispatch => {
    dispatch(beginAjaxCall());

    const assessmentAssociateId =  sampleRequest.assessmentAssociateId;
    const standardsVerificationSampleId = sampleRequest.standardsVerificationSampleId;
    
    return axios
      .get(`${config.API_GATEWAY.URL}/sample-standards-verification-requests/version/${assessmentAssociateId}/${standardsVerificationSampleId}`)
      .then((version: { data: string; }) => {
          const latestVersion: string = version.data;

          // Get version and check if it is different, only get full sample media request if it has changed.
          if (sampleRequest.version !== latestVersion) {
            return axios
              .get(`${config.API_GATEWAY.URL}/sample-standards-verification-requests/${assessmentAssociateId}/${standardsVerificationSampleId}`)
              .then((response: { data: any; }) => {
                const sampleStandardsVerificationRequest = response.data;

                const mr = new SampleRequest();
                      mr.allocationId = sampleStandardsVerificationRequest.allocationId;
                      mr.assessmentAssociateId = sampleStandardsVerificationRequest.assessmentAssociateId;
                      mr.standardsVerifierName = sampleStandardsVerificationRequest.standardsVerifierName;
                      mr.createdDateTime = sampleStandardsVerificationRequest.createdDateTime;
                      mr.deadLineDateTime = sampleStandardsVerificationRequest.deadLineDateTime;
                      mr.lastModifiedDateTime = sampleStandardsVerificationRequest.lastModifiedDateTime;
                      mr.learningProviderId = sampleStandardsVerificationRequest.learningProviderId;
                      mr.consortiaId = sampleStandardsVerificationRequest.consortiaId;
                      mr.failureReason = sampleStandardsVerificationRequest.failureReason;
                      mr.previousState = sampleStandardsVerificationRequest.previousState;
                      mr.learningProviderName = sampleStandardsVerificationRequest.learningProviderName?sampleStandardsVerificationRequest.learningProviderName: '';
                      mr.learningProviderCode = sampleStandardsVerificationRequest.learningProviderCode ?sampleStandardsVerificationRequest.learningProviderCode :'';
                      mr.qualificationId = sampleStandardsVerificationRequest.qualificationId;
                      mr.qualificationName =sampleStandardsVerificationRequest.qualificationName? sampleStandardsVerificationRequest.qualificationName :'';
                      mr.qualificationGroupCode = sampleStandardsVerificationRequest.qualificationGroupCode? sampleStandardsVerificationRequest.qualificationGroupCode: '';
                      mr.standardsVerificationSampleId = sampleStandardsVerificationRequest.standardsVerificationSampleId;
                      mr.status =  sampleStandardsVerificationRequest.status; 
                      mr.selectedUnits = sampleStandardsVerificationRequest.selectedUnits;
                      mr.submittedUnits = sampleStandardsVerificationRequest.submittedUnits;
                      mr.mediaSetId = sampleStandardsVerificationRequest.mediaSetId;
                      mr.submittedBy = sampleStandardsVerificationRequest.submittedBy;
                      mr.submissionDate = sampleStandardsVerificationRequest.submissionDate;
                      mr.version = sampleStandardsVerificationRequest.version;
                      mr.parentRequests = sampleStandardsVerificationRequest.parentRequests;
                      mr.parentLearningProviderCode=sampleStandardsVerificationRequest.parentLearningProviderCode;
                dispatch(updateSampleStandardsVerificationRquestsSuccessAction(mr));
              })
              .catch((error: string) => {
                dispatch(ajaxCallError(error));
                throw error;
              });
          }
          else{
            return;
          }
      })
      .catch((error: string) => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};


export const countSampleSVRequest = (
  assessmentAssociateId: string
): ThunkResult<Promise<void>> => {
  return dispatch => {
    dispatch(beginAjaxCall());

    
    
    
    return axios
      .get(`${config.API_GATEWAY.URL}/sample-standards-verification-requests/count/${assessmentAssociateId}`)
      .then((count: { data: any; }) => {
          const Count =  count.data;
          const counts  = Count.standardsVerificationSampleRequestCount;
          
          // Get count and check if it is different, only get full sample media requests if it has changed.
          if (localStorage.getItem('sampleRequestCounts') !== counts.toString()) {
            localStorage.setItem('sampleRequestCounts',counts)
            
            return axios
            .get(`${config.API_GATEWAY.URL}/sample-standards-verification-requests`, {        
              params: {
                assessmentAssociateId 
              }
            })
            .then((response: { data: any; }) => {
              const sampleStandardsVerificationRequests =  response.data;      
                  
                dispatch(
                  getSampleStandardsVerificationRquestsSuccessAction(
                    sampleStandardsVerificationRequests.map((sampleStandardsVerificationRequest: SampleRequest) => {
                     
                      const mr = new SampleRequest();
                      mr.allocationId = sampleStandardsVerificationRequest.allocationId;
                      mr.assessmentAssociateId = sampleStandardsVerificationRequest.assessmentAssociateId;
                      mr.standardsVerifierName = sampleStandardsVerificationRequest.standardsVerifierName;
                      mr.createdDateTime = sampleStandardsVerificationRequest.createdDateTime;
                      mr.deadLineDateTime = sampleStandardsVerificationRequest.deadLineDateTime;
                      mr.lastModifiedDateTime = sampleStandardsVerificationRequest.lastModifiedDateTime;
                      mr.learningProviderId = sampleStandardsVerificationRequest.learningProviderId;
                      mr.failureReason = sampleStandardsVerificationRequest.failureReason;
                      mr.previousState = sampleStandardsVerificationRequest.previousState;
                      mr.learningProviderName = sampleStandardsVerificationRequest.learningProviderName?sampleStandardsVerificationRequest.learningProviderName:'';
                      mr.learningProviderCode = sampleStandardsVerificationRequest.learningProviderCode ? sampleStandardsVerificationRequest.learningProviderCode : '';
                      mr.consortiaId = sampleStandardsVerificationRequest.consortiaId;
                      mr.qualificationId = sampleStandardsVerificationRequest.qualificationId;
                      mr.qualificationName = sampleStandardsVerificationRequest.qualificationName? sampleStandardsVerificationRequest.qualificationName :'';
                      mr.qualificationGroupCode = sampleStandardsVerificationRequest.qualificationGroupCode?sampleStandardsVerificationRequest.qualificationGroupCode : '';
                      mr.standardsVerificationSampleId = sampleStandardsVerificationRequest.standardsVerificationSampleId;
                      mr.status =  sampleStandardsVerificationRequest.status; 
                      mr.selectedUnits = sampleStandardsVerificationRequest.selectedUnits;
                      mr.submittedUnits = sampleStandardsVerificationRequest.submittedUnits;
                      mr.mediaSetId = sampleStandardsVerificationRequest.mediaSetId;
                      mr.submittedBy = sampleStandardsVerificationRequest.submittedBy;
                      mr.submissionDate = sampleStandardsVerificationRequest.submissionDate;
                      mr.version = sampleStandardsVerificationRequest.version;
                      mr.parentRequests = sampleStandardsVerificationRequest.parentRequests;
                      mr.parentLearningProviderCode=sampleStandardsVerificationRequest.parentLearningProviderCode;
                      return mr;
                    })
                  )
                );
              })
              .catch((error: string) => {
                dispatch(ajaxCallError(error));
                throw error;
              });
          }
          else{
            return;
          }
      })
      .catch((error: string) => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const refreshRequest = (
  assessmentAssociateId: string
): ThunkResult<Promise<void>> => {
  return dispatch => {
    dispatch(beginAjaxCall());

    
    
    
    // return axios
    //   .get(`${config.API_GATEWAY.URL}/sample-standards-verification-requests/count/${assessmentAssociateId}`)
    //   .then(count => {
    //       const Count =  count.data;
    //       let counts  = Count['standardsVerificationSampleRequestCount'];
          
          // Get count and check if it is different, only get full sample media requests if it has changed.
          // if (localStorage.getItem('sampleRequestCounts') !== counts.toString()) {
           // localStorage.setItem('sampleRequestCounts',counts)
                  
            return axios
            .get(`${config.API_GATEWAY.URL}/sample-standards-verification-requests`, {        
              params: {
                assessmentAssociateId 
              }
            })
            .then((response: { data: any; }) => {
              const sampleStandardsVerificationRequests =  response.data;      
                  
                dispatch(
                  getSampleStandardsVerificationRquestsSuccessAction(
                    sampleStandardsVerificationRequests.map((sampleSVRequest: SampleRequest) => {
                     const mr = new SampleRequest();
                      mr.academicYear = sampleSVRequest.academicYear;
                      mr.allocationId = sampleSVRequest.allocationId;
                      mr.assessmentAssociateId = sampleSVRequest.assessmentAssociateId;
                      mr.standardsVerifierName = sampleSVRequest.standardsVerifierName;
                      mr.createdDateTime = sampleSVRequest.createdDateTime;
                      mr.deadLineDateTime = sampleSVRequest.deadLineDateTime;
                      mr.lastModifiedDateTime = sampleSVRequest.lastModifiedDateTime;
                      mr.learningProviderId = sampleSVRequest.learningProviderId;
                      mr.failureReason = sampleSVRequest.failureReason;
                      mr.previousState = sampleSVRequest.previousState;
                      mr.learningProviderName = sampleSVRequest.learningProviderName?sampleSVRequest.learningProviderName:'';
                      mr.learningProviderCode = sampleSVRequest.learningProviderCode?sampleSVRequest.learningProviderCode:'';
                      mr.consortiaId = sampleSVRequest.consortiaId;
                      mr.qualificationId = sampleSVRequest.qualificationId;
                      mr.qualificationName = sampleSVRequest.qualificationName ?  sampleSVRequest.qualificationName:'';
                      mr.qualificationGroupCode = sampleSVRequest.qualificationGroupCode ?  sampleSVRequest.qualificationGroupCode :'';
                      mr.standardsVerificationSampleId = sampleSVRequest.standardsVerificationSampleId;
                      mr.status =  sampleSVRequest.status; 
                      mr.selectedUnits = sampleSVRequest.selectedUnits;
                      mr.submittedUnits = sampleSVRequest.submittedUnits;
                      mr.mediaSetId = sampleSVRequest.mediaSetId;
                      mr.submittedBy = sampleSVRequest.submittedBy;
                      mr.submissionDate = sampleSVRequest.submissionDate;
                      mr.version = sampleSVRequest.version;
                      mr.parentRequests = sampleSVRequest.parentRequests;
                      mr.parentLearningProviderCode=sampleSVRequest.parentLearningProviderCode;
                      return mr;
                    })
                  )
                );
              })
              .catch((error: string) => {
                dispatch(ajaxCallError(error));
                throw error;
              });
          // }
          // else{
          //   return;
          // }
      // })
      // .catch(error => {
      //   dispatch(ajaxCallError(error));
      //   throw error;
      // });
  };
};
