import * as React from 'react';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import moment from "moment";
import axios from 'axios';

import CreateSampleRequestForm from './create-sample-request-form'
import { SampleRequest } from '../../../models/sample-request';
import config from "../../../app-config";
const pageTitle ="Create Sample Request";
import loading from '../../../components/callback/loading.svg';


export type OwnProps = RouteComponentProps<{}>;

interface Props extends DispatchProps, StateProps, OwnProps { }

export interface DispatchProps {   
  getEnv?: () => Promise<void>;
}
interface LocalState {
  isBusy: boolean;
  sampleRequest: SampleRequest,
  validations: Validations,
  loading: boolean,
}

export interface StateProps {
  userPermissionValidated: boolean,
  showCreateMenu: boolean
}

export class Validations {
  errors: any = {};
  validationFlags: ValidationFlags = new ValidationFlags;
}

export class ValidationFlags {
  deadlineDateTime: boolean = true;
  academicYear: boolean = false
  allocationId: boolean = false;
  learningProviderId: boolean = false;
  learningProviderName: boolean = false;
  learningProviderCode: boolean = false;
  assessmentAssociateId: boolean = false;
  assessmentAssociateName: boolean = false;
  qualificationId: boolean = false;
  qualificationName: boolean = false;
  qualificationGroupCode: boolean = false;
}

export class CreateSampleRequests extends React.Component<Props, LocalState, StateProps> {
  constructor(props: Props) {
    super(props);
    document.title=pageTitle;
    this.state = {
      isBusy: false,
      sampleRequest: Object.assign(new SampleRequest(), { proposedSamplingDate: moment(new Date(), "DD/MM/YYYY").toDate() }),
      validations: new Validations(),
      loading: true
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.saveNewMediaSet = this.saveNewMediaSet.bind(this);
    this.render = this.render.bind(this);
    this.onDeadlineChanged = this.onDeadlineChanged.bind(this);
    this.onClear = this.onClear.bind(this);
    this.validateInputElement = this.validateInputElement.bind(this);
    this.isSaveButtonEnabled = this.isSaveButtonEnabled.bind(this);
  }

  render() {
    const { isBusy, sampleRequest } = this.state;
    const style = {
      backgroundColor: 'white',
      bottom: 0,
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      left: 0,
      position: 'absolute' as 'absolute',
      right: 0,
      top: 0,
      width: '100vw'
    };
    return (
      <div>
        <div className="pe-template__single">
          <h1 className="pe-page-title">{pageTitle}</h1>
        </div>
        <div className="pe-template__single content">
          <section>
            { this.state.loading &&
            <div style={style}>
              <img src={loading} alt="loading" style={{width:"142px"}} />
            </div>}
            {isBusy === false && <CreateSampleRequestForm
              sampleRequest={sampleRequest}
              onFieldChange={this.onFieldChange}
              onSave={this.saveNewMediaSet}
              onDeadlineChanged={this.onDeadlineChanged}
              onClear={this.onClear}
              errors={this.state.validations.errors}
              saveButtonEnabled={this.isSaveButtonEnabled()} />}
            {isBusy &&
              'Loading ...'}
          </section>
        </div>
      </div>
    );
  }

  componentDidMount(){
    if(this.props.userPermissionValidated === false) {
      this.props.history.push('/home')
    }
    if(this.props.getEnv && this.props.showCreateMenu === false){
      this.props.getEnv().then(this.showNotFoundPage)
    } else {
        this.setState({loading:false})
    }
  }

  componentWillReceiveProps(){
    if(this.props.userPermissionValidated === false) {
      this.props.history.push('/home')
    } 
  }

  showNotFoundPage = () =>{
    if(this.props.showCreateMenu === false) {
      this.setState({loading:false})
      this.props.history.push('/not-found');
    }else {
      this.setState({loading:false})
    }
  }

  onFieldChange(event: React.FormEvent<HTMLInputElement>) {
    event.preventDefault();

    const element = event.target as HTMLInputElement;
    const sampleRequest = Object.assign({}, this.state.sampleRequest);
    sampleRequest[element.id] = element.value;

    const validations = Object.assign({}, this.state.validations, this.validateInputElement(element.id, element.value));

    return this.setState({ sampleRequest, validations });
  }

  saveNewMediaSet(event: Event) {
    event.preventDefault();
    this.setState({ isBusy: true });

    axios
      .post(
        `${config.API_GATEWAY.URL}/sample-requests`,
        JSON.stringify(this.state.sampleRequest),
        { headers: { 
          "content-type": "application/json"
        } }
      ).then(() => {
        this.onClear();
        this.setState({ isBusy: false });
      })
  }

  onDeadlineChanged(newDeadline: string) {
    const sampleRequest = Object.assign({}, this.state.sampleRequest);
    sampleRequest.proposedSamplingDate = moment(newDeadline, "DD/MM/YYYY").toDate();

    const validations = Object.assign({}, this.state.validations, this.validateInputElement('deadlineDateTime', newDeadline));

    return this.setState({ sampleRequest, validations });
  }

  onClear() {
    this.setState({
      sampleRequest: Object.assign({}, new SampleRequest()),
      validations: new Validations()
    });
  }

  isSaveButtonEnabled(): boolean {
    const flags = this.state.validations.validationFlags;
    return flags.allocationId
      && flags.academicYear
      && flags.qualificationId
      && flags.qualificationName
      && flags.qualificationGroupCode
      && flags.deadlineDateTime
      && flags.learningProviderId
      && flags.learningProviderName
      && flags.learningProviderCode
      && flags.assessmentAssociateId
      && flags.assessmentAssociateName;
  }

  validateInputElement(prop: string, value: any): Validations {
    const result: Validations = Object.assign({}, this.state.validations);

    switch (prop) {
      case 'academicYear':
      case 'allocationId':
      case 'learningProviderId':
      case 'learningProviderName':
      case 'learningProviderCode':
      case 'assessmentAssociateId':
      case 'assessmentAssociateName':
      case 'qualificationId':
      case 'qualificationName':
      case 'qualificationGroupCode':
      case 'deadlineDateTime':
        {
          if (value === '') {
            result.errors[prop] = 'required';
            result.validationFlags[prop] = false;
          } else {
            result.errors[prop] = undefined;
            result.validationFlags[prop] = true;
          }
        }
    }

    return result;
  }

}

export default CreateSampleRequests;
