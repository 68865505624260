import * as React from "react";
import UnitSelectionListRow from "./unit-selection-list-row";
import Unit from "../../../models/unit";
import {Button,TextInput,Table, Checkbox} from 'ukas.uux.react-components';
import { useUserLoginState } from "../../../shared/context-api/hooks";
import { HasWriteAccess } from "../../../components/common/user-validation";

interface UnitSelectionListProps {
  onSelect: (
    unitId: string,
    unitName: string,
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => void;
  onSort: (item: string) => void;
  getIconName: (columnName: string) => string;
  onFieldChange: (event: React.FormEvent<HTMLInputElement>) => void;
  units: Unit[];
  goBack: () => void;
  goNext: () => void; 
  status: string;
  previousState : string;
  ondeActivate: () => void;
  onreactivate: () => void;
  isDisabled: boolean; 
  selectedUnits: string[];
  onSelectCheckAll:(event: React.MouseEvent<HTMLSpanElement, MouseEvent>  ) => void;
  checkBoxAll:boolean;
  isTeamMember: boolean;
}

const UnitSelectionList = ({
  onSelect,
  onSort,
  getIconName,
  onFieldChange,
  units,
  goBack,
  goNext,
  isDisabled,
  ondeActivate,
  onreactivate,
  selectedUnits,
  onSelectCheckAll,
  status,
  checkBoxAll,
  isTeamMember
}: UnitSelectionListProps) => {
  const enableDeactivateButton =
    status === "CREATE_REQUEST" || status === "REQUEST_CREATION_IN_PROGRESS";
  const enableReactivateButton = status === "REQUEST_DEACTIVATED";
  const userLoginstate = useUserLoginState();

return (
  <div>    
    <div className="pe-table">
      <div className="pull-left hide">
        <TextInput
          id="searchTerm"
          label="Search"
          onChange={onFieldChange} maxLength={10}        
          /><br />
      </div>
      
    </div> 
    {/* <table className="pe-table pe-table--active-headers pe-table--selectable pe-table--sortable"> */}
    <Table responsive="xl">
      <thead>
        <tr>
          <td>Unit</td>
          {!isTeamMember && (
            <th><div className="pe-checkbox" >
              <Checkbox
                checked={checkBoxAll}
                disabled={status === "REQUEST_COMPLETED" || !HasWriteAccess(userLoginstate)}
                aria-label="this is an aria-label"
                onClick={e => { onSelectCheckAll(e) }}
              />  <span>Select</span>
            </div></th>
          )}
        </tr>
      </thead>
      <tbody>
        {units.map((unitDetails: Unit) => (
          unitDetails.assessmentMethod === '2' && (
          <UnitSelectionListRow
            key={unitDetails.unitId}
            onSelect={onSelect}
            UnitSelectionList={unitDetails}
            selectedUnits = {selectedUnits}
            isTeamMember ={isTeamMember}
           status ={status}
          /> )        
        ))}
      </tbody>
      </Table>   
    <Button onClick={goBack}>Back</Button>
    <div className="pull-right">
        
        {enableReactivateButton && (
          <Button onClick={onreactivate} disabled={isTeamMember || !HasWriteAccess(userLoginstate)  ? true : false}>
            Reactivate Request
          </Button>
        )}
        {enableDeactivateButton && (
          <Button onClick={ondeActivate}  disabled={isTeamMember || !HasWriteAccess(userLoginstate)  ? true : false}>
            Deactivate Request
          </Button>
        )}
        &nbsp;&nbsp;&nbsp;&nbsp;
        {!enableReactivateButton &&
        <Button
          onClick={goNext}
          variant="primary"
          disabled={isDisabled ? true : false}
        >
          Next
        </Button>
}
   </div>
    <p>&nbsp;</p>
  </div>
);
        };

export default UnitSelectionList;
