import axios from "axios";
import config from "../app-config";
import User from "../models/user";
import Auth from "../actions/auth/auth";

const auth = new Auth();

export const getUser = async (forgeRockId:string=""):Promise<any> => {
   
    const axiosConfig = {
      headers: { Authorization: `Bearer ${auth.getAccessToken()}`, 'x-id-token': `${auth.getIdToken()}` }
    }
   
  
        const mockUsers = config.IS_MOCK.MOCK_USERS ? 1 : 0;

         try {
        const response = await axios
            .get(
                `${config.API_GATEWAY.URL}/user/getuser/${forgeRockId}/${mockUsers}`,
                axiosConfig
            );
        const userDetails = response.data as User;
        window.localStorage.setItem("internalUserHasValidPermissions", (!userDetails.userPermissions ? false : (userDetails.userPermissions && userDetails.isUserPermissionValid)).toString());
        return userDetails;
    } catch (error) {
        // dispatch(ajaxCallError(error));
        throw error;
    }



  }