export default class User {
    aaNumber: string;
    associatedLearningProviders: associatedLearningProviders[];
    isUserPermissionValid: boolean;
    lockedUntil?: Date = new Date();
    jobRoles?: jobRole[];
    isAATeamLead?: boolean;
    lpExclusions : string[];
     userPermissions : userRoleAccess;
}

export class userRoleAccess {
    lwaReadAccess: boolean;
    lwaWriteAccess: boolean;
  }

export class learningProvider {
    vqLearningProviderCode: string;
    gqLearningProviderCode: string;
    learningProviderId: number;
    learningProviderName: string;
}

export class associatedLearningProviders {
    learningProviderId: string;
    learningProvider: learningProvider[];
}

export class permissionGroups {
    permissionGroupId: number;
    permissionGroupName: string;
}
 export class jobRole {
    jobRoleCode: string;
    jobRoleDescription?: string;
    jobRoleId: number;
    lastModifiedDateTime?: Date = new Date();
 }
	
