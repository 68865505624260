import * as React from "react";
// import { Component } from "react"; 
import _ from "lodash";
import { RouteComponentProps } from "react-router-dom";

import LearnerSelectionList from "./learner-selection-list";
import Learners from "../../../models/learner";
import { SelectedUnit } from "../../../models/unit";
import { SampleRequest } from "../../../models/sample-request";


export interface StateProps {
  sampleRequest: SampleRequest;
  SVLearnerDetails: Learners[];
  readOnly: boolean;
}

interface NewLearner {
  unitId: string;
  assessmentAssociateId: string;
  standardsVerificationSampleId: string;
  learnerIds: string[];
}



export interface DispatchProps {
  getStandardVerificationLearners: (
    learningProviderId: string,
    qualificationId: string,
    unitId: string,
    academicYear:string
  ) => Promise<void>;
  getStandardVerificationLearnersByconsortiaId: (
    consortiaId: number,
    qualificationId: string,
    academicYear: string   
  ) => Promise<void>;
  addLearnerInfoInput: (newLearnerSet: NewLearner) => Promise<void>;
  removeLearnerInfoInput: (newLearnerSet: NewLearner) => Promise<void>;
}

export type OwnProps = RouteComponentProps<{ allocationId: string }>;

interface Props extends DispatchProps, StateProps, OwnProps {
  unitId: string;
  unitName: string;
  singleSampleStandardsVerificationRequest: SampleRequest;
  closeEvnt: any;
  display: boolean;
}

interface State {
  isBusy: boolean;
  selectedColumn: string;
  direction: string;
  searchText: string;
  selected: boolean;
  assiagnLearners: object;
  selectedIds: string[];
}

export class LearnerSelection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const unit = _.find(
      this.props.sampleRequest.selectedUnits,
      (u: SelectedUnit) => u.unitId === this.props.unitId
    );

    const ids: string[] = [];
    if (unit) {
      unit.selectedLearners.forEach(element => {
        ids.push(element.learnerId.toString());
      });

      // ids.push(unit.selectedLearners.map((sl) => sl.learnerId.toString()));
    }

    this.state = {
      isBusy: true,
      selectedColumn: "",
      direction: "",
      searchText: "",
      selected: false,
      assiagnLearners: {},
      selectedIds: ids.length > 0 ? ids : []
    };

    this.onSelect = this.onSelect.bind(this);
    this.handleColumnSort = this.handleColumnSort.bind(this);
    this.getIconName = this.getIconName.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.closeEvent = this.closeEvent.bind(this);
    this.getSelectedLearners = this.getSelectedLearners.bind(this);
    this.onSelectCheckAll = this.onSelectCheckAll.bind(this);
    this.checkBoxAll = this.checkBoxAll.bind(this);
  }

  render() {
    const { isBusy, selectedColumn } = this.state;

    return (
      <div className="unitselection">
        <div id="guidanceText">
          {this.props.readOnly
            ? "The listed learner(s) are linked to the selected unit."
            : "Choose learner(s) to link to the selected unit."}
        </div>
        <div className="pe-template__single content">
          <section>
            <div>
              <div className="pe-row">
                <div className="pe-col-6">
                  <div className="pe-label small-gap">
                    <span className="pe-label pe-label--bold">
                      Learning Provider
                    </span><br />
                    {
                      this.props.singleSampleStandardsVerificationRequest
                      .parentLearningProviderCode ? this.props.singleSampleStandardsVerificationRequest.parentLearningProviderCode : this.props.singleSampleStandardsVerificationRequest.learningProviderCode
                    }
                    &nbsp;
                    {
                      this.props.singleSampleStandardsVerificationRequest
                        .learningProviderName
                    }&nbsp;
                    <b>{this.props.singleSampleStandardsVerificationRequest.consortiaId ? '(C)': '' }</b>
                  </div>
                </div>
                <div className="pe-col-6">
                  <div className="pe-label small-gap">
                    <span className="pe-label pe-label--bold">
                      Qualification
                    </span><br />
                    {
                      this.props.singleSampleStandardsVerificationRequest
                        .qualificationGroupCode
                    }
                    &nbsp;
                    {
                      this.props.singleSampleStandardsVerificationRequest
                        .qualificationName
                    }
                    &nbsp;
                    <b className="second-sample-text-color">{this.props.singleSampleStandardsVerificationRequest.parentRequests && this.props.singleSampleStandardsVerificationRequest.parentRequests.length > 0 ? "("+(this.props.singleSampleStandardsVerificationRequest.parentRequests.length + 1)+")" : ""}</b>
                  </div>
                </div>
              </div>
              <div className="pe-row"/>
              <div className="pe-row">
                <div className="pe-col-6">
                  <div className="pe-label small-gap">
                    <span className="pe-label pe-label--bold">Unit</span><br />
                    {this.props.unitName}
                  </div>
                </div>
              </div>
            </div>

            {!isBusy && this.props.SVLearnerDetails &&
              this.props.SVLearnerDetails.length > 0 && (
                <LearnerSelectionList
                  learners={
                    selectedColumn === ""
                      ? this.filter(this.props.SVLearnerDetails)
                      : this.sort(this.props.SVLearnerDetails)
                  }
                  onSelect={this.onSelect}
                  onSort={this.handleColumnSort}
                  getIconName={this.getIconName}
                  singleSampleStandardsVerificationRequest={
                    this.props.singleSampleStandardsVerificationRequest
                  }
                  closeEvent={this.closeEvent}
                  selectedLearners={this.getSelectedLearners()}
                  display={this.props.display}
                  readOnly={this.props.readOnly}
                  onSelectCheckAll={this.onSelectCheckAll}
                  checkBoxAll={this.checkBoxAll()}
                  onFieldChange={this.onFieldChange}
                  isConsortia ={ this.isConsortia() }
                  isTeamMember = {this.isTeamMember()}
                />
              )}
              {!isBusy && this.props.SVLearnerDetails.length === 0 && (

                <div className="noData">No Registered Learners</div>
              )}
            {isBusy && "Loading ..."}
          </section>
        </div>
      </div>
    );
  }
  isConsortia(){
    if(this.props.singleSampleStandardsVerificationRequest.consortiaId) {
    return true
    }
    else {
    return false;
    }
  }

  componentDidMount() {

    // Get data to load into page from qualification id passed in as part of the route
    this.handleColumnSort('firstName');
    if (this.props.singleSampleStandardsVerificationRequest) {
      if(this.props.singleSampleStandardsVerificationRequest.consortiaId){
        this.props.getStandardVerificationLearnersByconsortiaId(this.props.singleSampleStandardsVerificationRequest.consortiaId,
          this.props.singleSampleStandardsVerificationRequest.qualificationId,
          this.props.singleSampleStandardsVerificationRequest.academicYear  
        ).then(() => {
          this.setState({ isBusy: false })
        });
      }else{
      this.props.getStandardVerificationLearners(this.props.singleSampleStandardsVerificationRequest.learningProviderId,
        this.props.singleSampleStandardsVerificationRequest.qualificationId,
        this.props.unitId,
        this.props.singleSampleStandardsVerificationRequest.academicYear
      ).then(() => {
        this.setState({ isBusy: false })
      });
    }
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.sampleRequest && this.props.sampleRequest) {
      this.props.getStandardVerificationLearners(
        this.props.singleSampleStandardsVerificationRequest.learningProviderId,
        this.props.singleSampleStandardsVerificationRequest.qualificationId,
        this.props.unitId,
        this.props.singleSampleStandardsVerificationRequest.academicYear
      ).then(() => {
        this.setState({ isBusy: false })
      });
    }
  }

  getSelectedLearners() {
    const unit = _.find(
      this.props.sampleRequest.selectedUnits,
      (u: SelectedUnit) => u.unitId === this.props.unitId + ""
    );
    if (unit) {
      return unit.selectedLearners.map(sl => sl.learnerId);
    }

    return [];
  }

  onSearch() {
    const searchTerm = this.state.searchText;

    let filteredSVLearnerDetails = this.props.SVLearnerDetails;
    if (searchTerm !== "" && searchTerm.length >= 3) {
      filteredSVLearnerDetails = this.filter(this.props.SVLearnerDetails);
    }
    return filteredSVLearnerDetails;
  }


  onSelectCheckAll(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    const filteredSVLearnerDetails = this.onSearch();
    if (filteredSVLearnerDetails.length !== this.getSelectedLearners().length) {
      const learnerDetails: object[] = [];
      filteredSVLearnerDetails.forEach(element => {
        const learnerDetailsObj = Object.assign({
          learnerId: element.learnerId.toString(),
          firstName: element.firstName,
          surname: element.surname,
          genderId: element.genderId,
          code: element.code,
          learningProviderCode: element.learningProviderCode,        
          learningProviderName: element.learningProviderName
        });
        learnerDetails.push(learnerDetailsObj)
      })
      
      const newLearnerSet = Object.assign({
        unitId: this.props.unitId.toString(),
        assessmentAssociateId: this.props.sampleRequest.assessmentAssociateId,
        standardsVerificationSampleId: this.props.sampleRequest.allocationId,
        learnerDetails
      });

      this.props.addLearnerInfoInput(newLearnerSet);
    } else {
      const learnerDetails: string[] = [];
      filteredSVLearnerDetails.forEach(element => {
        learnerDetails.push(element.learnerId.toString());
      });
      const newLearnerSet = Object.assign({
        unitId: this.props.unitId.toString(),
        assessmentAssociateId: this.props.sampleRequest.assessmentAssociateId,
        standardsVerificationSampleId: this.props.sampleRequest.allocationId,
        learnerIds: learnerDetails
      });

      this.props.removeLearnerInfoInput(newLearnerSet);
    }
  }

  checkBoxAll() {
    const filteredSVLearnerDetails = this.onSearch();

    return (filteredSVLearnerDetails.length === this.getSelectedLearners().length);

  }

  onSelect(
    learnerId: string,
    Learner: Learners,
    singlesampleRequest: SampleRequest,
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) {
    event.preventDefault();

    const action = this.getSelectedLearners().includes(learnerId)
      ? "removeLearner"
      : "addLearner";
    
    if (action === "addLearner") {
      const learnerDetails = [Object.assign({
        learnerId: Learner.learnerId.toString(),
        firstName: Learner.firstName,
        surname: Learner.surname,
        genderId: 0,
        code: Learner.code,
        learningProviderCode: Learner.learningProviderCode,        
        learningProviderName: Learner.learningProviderName
      })];

      const newLearnerSet = Object.assign({
        unitId: this.props.unitId.toString(),
        assessmentAssociateId: singlesampleRequest.assessmentAssociateId,
        standardsVerificationSampleId: singlesampleRequest.allocationId,
        learnerDetails
      });

      this.props.addLearnerInfoInput(newLearnerSet);
    } else if (action === "removeLearner") {
      const newLearnerSet = Object.assign({
        unitId: this.props.unitId.toString(),
        assessmentAssociateId: singlesampleRequest.assessmentAssociateId,
        standardsVerificationSampleId: singlesampleRequest.allocationId,
        learnerIds: [Learner.learnerId.toString()]
      });

      this.props.removeLearnerInfoInput(newLearnerSet);
    }
  }

  closeEvent = () => {
    this.props.closeEvnt();
  };

  onFieldChange = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    const element = event.target as HTMLInputElement;
    return this.setState({ searchText: (element.value.toLowerCase()) });
  };

  getIconName = (selectedColumn: string) => {
    if (this.state.selectedColumn === selectedColumn) {
      return this.state.direction === "up" ? "sort-up-18" : "sort-down-18";
    }

    return "sortable-18";
  };

  handleColumnSort = (selectedColumn: string) => {
    return () => {
      let direction = "down";

      if (this.state.selectedColumn === selectedColumn) {
        direction = this.state.direction === "up" ? "down" : "up";
      }

      this.setState({ selectedColumn, direction });
    };
  };

  sort(SVLearnerDetails: Learners[]): Learners[] {
    const lodashDirection = this.state.direction === 'down' ? 'desc' : 'asc';

    const sortedLearnerDetails = _.orderBy(
      SVLearnerDetails,
      [
        SVLearnerDetail => {
          if (
            typeof SVLearnerDetail[this.state.selectedColumn].toLowerCase ===
            "function"
          ) {
            return SVLearnerDetail[this.state.selectedColumn].toLowerCase();
          } else {
            return SVLearnerDetail[this.state.selectedColumn];
          }
        }
      ],
      [lodashDirection]
    ) as Learners[];

    return this.filter(sortedLearnerDetails);
  }

  filter(SVLearnerDetails: Learners[]): Learners[] {
    const searchTerm = this.state.searchText;
    if (searchTerm !== '' && searchTerm.length >= 3) {
      const sampleSearchRequests = JSON.parse(JSON.stringify(SVLearnerDetails));
      const columnNames = ["code", "firstName", "surname","learningProviderCode","learningProviderName"];
      return _.filter(sampleSearchRequests, sampleSearchRequest => {
        return _(sampleSearchRequest).pick(columnNames).some(searchstring => {
          return _(searchstring).toLower().includes(searchTerm);
        });
      }) as Learners[];
    }
    // console.log(SVLearnerDetails)
    return SVLearnerDetails;
  }

  isTeamMember():boolean {    
    
    let isTeamMember = false;
    const proxyAATeamMember = localStorage.getItem('isTeamMember')
    if(proxyAATeamMember === 'true'){
      isTeamMember = true;
    }
    
    return isTeamMember;
  }
}

export default LearnerSelection;
