import * as React from 'react';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';

import { SampleRequest } from '../../../models/sample-request';
import { AuthProfile } from '../../../models/auth';
import SampleStandardsVerificationRequestsList from './sample-standards-verification-requests-list';
import TextHelper from "../../shared/text-formatting";
import moment from 'moment';
import { statusEnums, userTypeEnums } from "../../shared/enums";
import loading from '../../../components/callback/loading.svg';
// const PearsonElementsSDK = require("@pearson-components/elements-sdk/build/dist.elements-sdk");
require('../search-modal/search-modal.css'); 
import InformationModalGeneric from "../../shared/information-modal-generic"
const PearsonElementsSdk = require('@pearson-components/modal');
// const PearsonElementIconSDK = require('@pearson-components/elements-sdk/build/dist.elements-sdk');
import SearchModal from '../search-modal/search-modal.container.component';
import { Alert } from 'ukas.uux.react-components';
const pageTitle = "Sample Requests";


export interface StateProps {
  sampleRequests: SampleRequest[];
  profile: AuthProfile,
  userPermissionValidated: boolean
  lpExclusions: string[],
  isGetSampleMediaRequestCompleted: boolean,
  isGetSampleMediaRequestStarted: boolean
}
export interface DispatchProps {
  refreshSampleSVRequest: (sampleRequest: SampleRequest) => Promise<void>; 
  countSampleSVRequest: (assessmentAssociateId: string) => Promise<void>; 
  getSampleMediaRequestCompleted: (status: boolean)  => void;
  refreshRequest: (assessmentAssociateId: string) => Promise<void>; 
}

export type OwnProps = RouteComponentProps<{}>;

interface Props extends DispatchProps,StateProps, OwnProps { }

interface State {
  isBusy: boolean;
  selectedColumn: string;
  direction: string;
  searchText: string;
  refreshTimer: NodeJS.Timeout;
  // countTimer: NodeJS.Timeout;
  showSearchModal:boolean;
  showlpExclusionsModal:boolean
  showlpExclusionsModalText:string;
  year:string;
}

export class SampleStandardsVerificationRequests extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    document.title = pageTitle;
    this.state = {
      isBusy: true,
      selectedColumn: 'learningProviderCode',
      direction: '',
      searchText: '',
      refreshTimer: null as unknown as NodeJS.Timeout,
      // countTimer: setInterval(()=> this.countSVRequest(), 300000),
      showSearchModal: false,
      showlpExclusionsModal:false,
      showlpExclusionsModalText:'',
      year:'',
    };
    this.onSelect = this.onSelect.bind(this);
    this.getSampleStandardsVerificationRequestByAllocationId = this.getSampleStandardsVerificationRequestByAllocationId.bind(this);
    this.handleColumnSort = this.handleColumnSort.bind(this);
    this.getIconName = this.getIconName.bind(this);
    this.sort = this.sort.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.refreshSVRequest = this.refreshSVRequest.bind(this);
    this.refreshRequest =  this.refreshRequest.bind(this);
    this.sampleRequestsByYear = this.sampleRequestsByYear.bind(this);
    this.getExclusionConfirmationMsg = this.getExclusionConfirmationMsg.bind(this)
    this.cancelExclusionModal = this.cancelExclusionModal.bind(this)
    this.showExclusionModal = this.showExclusionModal.bind(this)
    this.onYearChange = this.onYearChange.bind(this)
  }

  render() {
    require('../search-modal/search-modal.css'); 
    const { isBusy, selectedColumn } = this.state;
    const text = {
      headerTitle: 'Select an AA',
      bodyText: 'Search for an AA with sample requests to populate the request screen. ',
      closeButtonSRText: 'Close',
      modalSaveButtonText: 'Ok',
      modalCancelButtonText: 'Close',
     
    };
    const exclusionText = {
      headerTitle: 'Forbidden Learning Provider',
      bodyText: 'Search for an AA with sample requests to populate the request screen. ',
      closeButtonSRText: 'Close',
      modalSaveButtonText: 'Ok',
      modalCancelButtonText: 'Close',
    }
    const style = {
      backgroundColor: 'white',
      bottom: 0,
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      left: 0,
      position: 'absolute' as 'absolute',
      right: 0,
      top: 0,
      width: '100vw'
    };
    // this.props.sampleStandardsVerificationRequests !== undefined && this.props.sampleStandardsVerificationRequests.length > 0 &&
    let displaySelect = true;
    if(!this.props.isGetSampleMediaRequestCompleted && this.props.isGetSampleMediaRequestStarted){
      displaySelect = false;
    }else{
      displaySelect = true;
    }
    return (
      <div className='content'>
        <div>
          <div className="pe-template__single">
            <h1 className="pe-page-title">{pageTitle}</h1>
          </div>
          <div className="pe-template__single">
          <p>
            Press any sample request to select it and move on to the next screen
          </p>
            <section>
              { !this.props.isGetSampleMediaRequestCompleted && this.props.isGetSampleMediaRequestStarted && 
              <div style={style}>
                <img src={loading} alt="loading" style={{width:"142px"}} />
              </div>
              }
              {
                ( this.props.profile.family_name && 
                  this.props.profile.family_name.toUpperCase() === userTypeEnums.INTERNAL_USER.toUpperCase()) && 
                  (localStorage.getItem('proxyAAId')==='0') &&                 
                  (
                    <Alert variant="danger">You must select an AA.</Alert>
                )}
                {this.props.isGetSampleMediaRequestCompleted}
                 { this.props.sampleRequests.length === 0 &&
                this.props.isGetSampleMediaRequestCompleted && 
                !userTypeEnums.INTERNAL_USER.toUpperCase() && (
                  <Alert variant="danger">There are no sample requests.</Alert>
                )}
              {!isBusy && this.props.sampleRequests.length > 0 && (
                <SampleStandardsVerificationRequestsList
                  sampleRequests={selectedColumn === '' ? this.filter(this.sampleRequestsByYear(this.props.sampleRequests)) : this.sort(
                    this.sampleRequestsByYear(this.props.sampleRequests)
                  )}
                  lpExclusions ={this.props.lpExclusions}
                  onSort={this.handleColumnSort}
                  getIconName={this.getIconName}
                  onFieldChange={this.onFieldChange}
                  onSelect={this.onSelect}
                  handleClick={this.handleClick}
                  onYearChange = {this.onYearChange}
                  displaySelect = {displaySelect}
                />
              )}
              {isBusy && this.props.sampleRequests && this.props.sampleRequests.length === 0 &&
                'Loading ...'}
                {isBusy && 'Loading ...'}
            </section>
          </div>
        </div>
        <PearsonElementsSdk.Modal
          isShown={this.state.showlpExclusionsModal}
          text={exclusionText}
          footerVisible={false}
          cancelBtnHandler={this.cancelExclusionModal}
          successBtnHandler={this.showExclusionModal}
        >
           <InformationModalGeneric 
            closeBtnHandler={this.cancelExclusionModal}
            infoMsgRender = {this.getExclusionConfirmationMsg}
          />
        </PearsonElementsSdk.Modal>
        <PearsonElementsSdk.Modal
          isShown={this.state.showSearchModal}
          text={text}
          footerVisible={true}
          cancelBtnHandler={this.cancelModalHandler}
          successBtnHandler={this.successModalHandler} saveBtnId="disableSuccessBtn">
            <SearchModal {...this.props as any} onClose={this.cancelModalHandler}  />
        </PearsonElementsSdk.Modal>
      </div>
    );
  }

  getExclusionConfirmationMsg(){
    return (<div>
      <br/>
      <p>
     {this.state.showlpExclusionsModalText}
      </p>
      <br/>
    </div>);
  }
  countSVRequest(){
    if(this.props.sampleRequests &&  this.props.sampleRequests[0] && this.props.sampleRequests[0].assessmentAssociateId){
    this.props.countSampleSVRequest(this.props.sampleRequests && this.props.sampleRequests[0].assessmentAssociateId);
    } 
  }
  cancelExclusionModal(){
    this.setState({ showlpExclusionsModal : false})
  }
  showExclusionModal(){
    this.setState({ showlpExclusionsModal : false})
  }

  refreshSVRequest() {
    // let refreshRequired: boolean = false;
    const sampleSVRequestStatus = ['RECALL_PENDING','REQUEST_SUBMITTED'];
      
    if (this.props.sampleRequests) {
      const refreshSampleRequest = this.props.sampleRequests.filter((f: SampleRequest) =>sampleSVRequestStatus.includes(f.status)
            
      )
      if(refreshSampleRequest){
        refreshSampleRequest.forEach((singleRefreshRequest) => { 
            this.props.refreshSampleSVRequest(singleRefreshRequest);
        })
      }
    }    
  }

  refreshRequest() {
     // console.log(this.props.sampleRequests.length);
    if(this.props.sampleRequests.length> 0 ){
      const assessmentAssociateId = this.props.sampleRequests[0].assessmentAssociateId;
     this.props.refreshRequest(assessmentAssociateId);
    }
    // // let refreshRequired: boolean = false;
     
  }

  cancelModalHandler =(searchSuccess:boolean = false)=>{
    this.setState({showSearchModal:false});
    searchSuccess ? this.props.history.push('/sample-requests') :  window.history.back();
  }
  successModalHandler =()=>{
    this.setState({showSearchModal:false});
    return this.props.history.push('/');
  }

  componentDidUpdate(){
    if(this.props.userPermissionValidated === false) {
      this.props.history.push('/')
    }
    
  }
  componentDidMount() {
    if(this.props.userPermissionValidated === false) {
      this.props.history.push('/')
    }
    
    //set sample refresh interval
    const interval = setInterval(()=> this.refreshRequest(), 20000);
    this.setState({refreshTimer: interval});
    
    // console.log("this.props.userPermissionValidated===",this.props.userPermissionValidated)
    this.handleColumnSort('qualificationName');
    this.setState({ isBusy: false })
    this.setState({showSearchModal:_.includes(this.props.match.path,'select-an-aa')}); 
    

    const scrl: number = parseInt(localStorage.getItem('SVSampleRequestScrollPosition')!,10) || 0;
    setTimeout(() => { window.scrollTo(0, scrl) }, 0);
    window.addEventListener('scroll', this.listenToScroll);
    if(this.props.sampleRequests.length> 0 ){
      const assessmentAssociateId = this.props.sampleRequests[0].assessmentAssociateId;
     this.props.refreshRequest(assessmentAssociateId);
    }
  }  
  
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)

    //clear sample refresh interval
    clearInterval(this.state.refreshTimer);

    // clearInterval(this.state.countTimer);
  }

  listenToScroll = () => {
    localStorage.setItem('SVSampleRequestScrollPosition', '' + window.scrollY);
  }

    sampleRequestsByYear(samplestandardsVerificationRequests: SampleRequest[]): SampleRequest[] {
      
    const getYear = localStorage.getItem('selectedYear');
    if (getYear !== '' ) {
      const sampleRequests = JSON.parse(JSON.stringify(samplestandardsVerificationRequests));
       
    return  _.filter(sampleRequests, (item)=> {
      return item.academicYear === getYear;
    });
    
    }
    return samplestandardsVerificationRequests;


  }

  filter(samplestandardsVerificationRequests: SampleRequest[]): SampleRequest[] {
    const searchTerm = this.state.searchText;
    if (searchTerm !== '' && searchTerm.length >= 3) {
      const sampleSearchRequests = JSON.parse(JSON.stringify(samplestandardsVerificationRequests));
      const columnNames = ["learningProviderCode", "learningProviderName", "qualificationGroupCode", "qualificationName", "deadLineDateTime", "status"];
      return _.filter(sampleSearchRequests, sampleSearchRequest => {
        sampleSearchRequest.status = TextHelper.titleCaseUploadStatus(sampleSearchRequest.status);
        if(sampleSearchRequest && sampleSearchRequest.deadLineDateTime) {
        sampleSearchRequest.deadLineDateTime = moment(new Date(sampleSearchRequest.deadLineDateTime)).format('DD MMM YYYY');
        }
        return _(sampleSearchRequest).pick(columnNames).some(searchstring => {
          return _(searchstring).toLower().includes(searchTerm);
        });
      }) as SampleRequest[];
    }
    return samplestandardsVerificationRequests;



  }

  onSelect(allocationId: string, event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
    event.preventDefault();

    const sampleRequest = this.getSampleStandardsVerificationRequestByAllocationId(
      this.props.sampleRequests,
      allocationId
    ) as SampleRequest;

    localStorage.setItem('selectedSVSampleRequest', allocationId);

    const sampleReqStatus =  TextHelper.getStatusText(sampleRequest.status);
    const previousStatus = TextHelper.getStatusText(sampleRequest.previousState);
    switch (sampleReqStatus) {
      case statusEnums.REQUEST_SUBMITTED:
      case statusEnums.RECALL_PENDING:
        this.props.history.push(
          `/sample-requests/link-learners/${sampleRequest.allocationId}`
        );
        break;
      case statusEnums. REQUEST_RECEIVED:
        this.props.history.push(
          `/sample-requests/access-learner-material/${sampleRequest.allocationId}`
        );
        break;
        case statusEnums.REQUEST_COMPLETED:
          previousStatus === statusEnums.REQUEST_SUBMITTED ?
            this.props.history.push(
              `/sample-requests/link-learners/${sampleRequest.allocationId}`
            ) : previousStatus === statusEnums. REQUEST_RECEIVED ?
             this.props.history.push(
              `/sample-requests/access-learner-material/${sampleRequest.allocationId}`
            ):this.props.history.push(
              `/sample-requests/unit-selection/${sampleRequest.allocationId}`
            ) ;
            break;
      default:
        this.props.history.push(
          `/sample-requests/unit-selection/${sampleRequest.allocationId}`
        );
    }      
  }

  getIconName = (selectedColumn: string) => {
    if (this.state.selectedColumn === selectedColumn) {
      return this.state.direction === 'up' ? 'sort-up-18' : 'sort-down-18';
    }

    return 'sortable-18';
  };

  handleColumnSort = (selectedColumn: string) => {
    return () => {
      let direction = 'down';

      if (this.state.selectedColumn === selectedColumn) {
        direction = this.state.direction === 'up' ? 'down' : 'up';
      }

      this.setState({ selectedColumn, direction });
    };
  };

  sort(samplestandardsVerificationRequests: SampleRequest[]): SampleRequest[] {
    const lodashDirection = this.state.direction === 'down' ? 'desc' : 'asc';
 let sortedSampleSamplestandardsVerificationRequestsRequests;
    if(this.state.selectedColumn !== "deadLineDateTime"){
   sortedSampleSamplestandardsVerificationRequestsRequests = _.orderBy(
      samplestandardsVerificationRequests,
      [
        standardsVerificationRequest => {
          if (
            typeof standardsVerificationRequest[this.state.selectedColumn].toLowerCase ===
            'function'
          ) {
            return standardsVerificationRequest[this.state.selectedColumn].toLowerCase();
          } else {
            return standardsVerificationRequest[this.state.selectedColumn];
          }
        }
      ],
      [lodashDirection]
    ) as SampleRequest[];
    }else{
      sortedSampleSamplestandardsVerificationRequestsRequests = _.orderBy(samplestandardsVerificationRequests, [( o ) => o.deadLineDateTime || ''], [lodashDirection]); 
    }
    return this.filter(sortedSampleSamplestandardsVerificationRequestsRequests);
  }

  getSampleStandardsVerificationRequestByAllocationId(sampleStandardsVerificationRequests: SampleRequest[], id: string) {
    const sampleStandardsVerificationRequest = sampleStandardsVerificationRequests.filter(
      (mr: SampleRequest) => mr.allocationId === id
    );
    if (sampleStandardsVerificationRequest.length) {
      return sampleStandardsVerificationRequest[0];
    }
    return null;
  }
  
  onYearChange(event: React.FormEvent<HTMLSelectElement>) {
    event.preventDefault();
    const element = event.target as HTMLInputElement;
    const value= element.value;
    // const valueSplit = value.split("/");
    // var  getYear = valueSplit[1].slice(0, -1); ;
    const valueSplit = value.split("(");
    const valueSplit1 = valueSplit[1].split("/");
    
    const getYear = valueSplit1[0];
    this.setState({year:getYear})

    localStorage.setItem('selectedYear',getYear)
  }

  onFieldChange(event: React.FormEvent<HTMLInputElement>) {
    event.preventDefault();
    const element = event.target as HTMLInputElement;
    return this.setState({ searchText: (element.value.toLowerCase()) });
  }

  handleClick(SVStatus: string, SVAllocationId: string, filteredList: boolean,lpExclusionsModalText:string, event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
    // SVStatus = this.getStatusText(SVStatus);
    // // console.log(SVStatus);
    // if (
    //   SVStatus === statusEnums.CREATE_REQUEST ||
    //   SVStatus === statusEnums.REQUEST_CREATION_IN_PROGRESS ||
    //   SVStatus === statusEnums. REQUEST_RECEIVED ||
    //   SVStatus === statusEnums.REQUEST_SUBMITTED || 
    //   SVStatus === statusEnums.RECALL_PENDING
    // ) {
      if(filteredList) {
        return this.setState({showlpExclusionsModal: true,showlpExclusionsModalText:lpExclusionsModalText})
       }
       else{
         this.onSelect(SVAllocationId, event);
  
        event.preventDefault();}
    


  }
}

export default SampleStandardsVerificationRequests;
