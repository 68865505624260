import * as React from "react";
import Unit from "../../../models/unit";
import { Checkbox } from "ukas.uux.react-components";
import { useUserLoginState } from "../../../shared/context-api/hooks";
import { HasWriteAccess } from "../../../components/common/user-validation";

interface UnitSelectionListRowProps {
  UnitSelectionList: Unit;
  onSelect: (
    unitId: string,
    unitName: string,
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => void;
  selectedUnits: string[];
  isTeamMember: boolean;
status:string;
}
const UnitSelectionListRow = ({
  UnitSelectionList,
  onSelect,
  selectedUnits,
  isTeamMember,
  status
}: UnitSelectionListRowProps) => {

  const prevChecked: boolean =
    selectedUnits.indexOf(UnitSelectionList.unitId + '') === -1 ? false : true;
  const unitId = UnitSelectionList.unitId + '';
  const userLoginstate = useUserLoginState();
  return (

    <tr className={prevChecked ? isTeamMember ? "disabled-selected" : "selected" : isTeamMember ? "disabled-not-selected" : ""}  >
      <td>
        {UnitSelectionList.name}
      </td>
      {!isTeamMember &&
        <td>
          <div className="pe-checkbox">
             <Checkbox
              checked={prevChecked}
              id={UnitSelectionList.unitId}
              disabled={!HasWriteAccess(userLoginstate) || status === "REQUEST_COMPLETED" || isTeamMember }
              aria-label="this is an aria-label"
              onClick={onSelect.bind(event, unitId, UnitSelectionList.name)}
            />
          </div>
        </td>
      }
    </tr>
  );
};

export default UnitSelectionListRow;
