import axios from "axios";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

import Unit from "../../../../models/unit";
import * as action_types from "../../../../actions/action-types";
import {
  ajaxCallError,
  beginAjaxCall,
  AjaxCallActions
} from "../../../../actions/ajax-status.actions";
import { 
  updateSampleStandardsVerificationRquestsSuccessAction, 
  SampleStandardsVerificationRequestsAction 
} from "../../../shared/sample-standards-verification-request.actions";

import config from "../../../../app-config";
import { StoreState } from "../../../../store/store-state";
import { SampleRequest } from "models/sample-request";


interface GetStandardVerificationUnitsSuccessAction extends Action {
  type: action_types.GET_STANDARDS_VERIFICATION_UNITS_SUCCESS;
  SVUnitDetails: Unit[];
}


export type SVUnitDetailsAction =
  | GetStandardVerificationUnitsSuccessAction
  | SampleStandardsVerificationRequestsAction
  | AjaxCallActions;

type ThunkResult<R> = ThunkAction<
  R,
  StoreState,
  undefined,
  SVUnitDetailsAction
>;

export const getStandardVerificationUnitsSuccessAction = (
  SVUnitDetails: Unit[]
): GetStandardVerificationUnitsSuccessAction => ({
  SVUnitDetails,
  type: action_types.GET_STANDARDS_VERIFICATION_UNITS_SUCCESS
});

export const getStandardVerificationUnits = (
  learningProviderId: string,
  qualificationId: string
): ThunkResult<Promise<void>> => {
  return dispatch => {
    dispatch(beginAjaxCall());
    return axios
      .get(`${config.API_GATEWAY.URL}/units/${learningProviderId}/${qualificationId}`)
      .then(response => {
        const SVUnitDetails = response.data;
        // console.log("ACTION============",JSON.stringify(SVUnitDetails));
        dispatch(
          getStandardVerificationUnitsSuccessAction(
            SVUnitDetails.map((SVUnitDetail: Unit) => {
              const svunits = new Unit();
              
              svunits.unitId = SVUnitDetail.unitId;
              // svunits.qualificationId = qualificationId;
              svunits.name = SVUnitDetail.name;
              svunits.code = SVUnitDetail.code;
              svunits.assessmentMethod = SVUnitDetail.assessmentMethod;
              return svunits;
              
            })
          )
        );
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const ondeActivateRequest = (
  standardsVerificationSampleId: string,
  assessmentAssociateId: string
): ThunkResult<Promise<void>> => {
  return dispatch => {
    let params = {}
    params = {
      standardsVerificationSampleId,
      assessmentAssociateId,
      }
    dispatch(beginAjaxCall());
    return axios
      .post(
        `${
          config.API_GATEWAY.URL
        }/sample-standards-verification-requests/deactivateRequest/`,
        params,
        { headers: { 
          "content-type": "application/json"
        } }
      )
      .then(postResponse => {
        const sampleRequest = postResponse.data as SampleRequest; 
        dispatch(updateSampleStandardsVerificationRquestsSuccessAction(sampleRequest));

      }) 
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};


export const onreActivateRequest = (
  standardsVerificationSampleId: string,
  assessmentAssociateId: string
): ThunkResult<Promise<void>> => {
  return dispatch => {
    let params = {}
    params = {
      standardsVerificationSampleId,
      assessmentAssociateId,
      }
    dispatch(beginAjaxCall());
    return axios
      .post(
        `${
          config.API_GATEWAY.URL
        }/sample-standards-verification-requests/reactivateRequest/`,
        params,
        { headers: { 
          "content-type": "application/json"
        } }
      )
      .then(postResponse => {
        const sampleRequest = postResponse.data as SampleRequest; 
        dispatch(updateSampleStandardsVerificationRquestsSuccessAction(sampleRequest));

      }) 
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};



export const modifySelectedStandardsVerificationUnits = (
  unitDetails:object[],
  unitIds:string[],
  action: string,
  standardsVerificationSampleId: string,
  assessmentAssociateId: string
): ThunkResult<Promise<void>> => {
  return dispatch => {
    let params={};
    if (action === 'addunit'){
      params = {
        standardsVerificationSampleId,
        assessmentAssociateId,
        unitDetails: unitDetails
      }
    } else {
      params = {
        standardsVerificationSampleId,
        assessmentAssociateId,
        unitIds: unitIds
      }
    }
    dispatch(beginAjaxCall());
    
    return axios
      .post(
        `${
          config.API_GATEWAY.URL
        }/sample-standards-verification-requests/${action}/`,
        params,
        { headers: { 
          "content-type": "application/json"
        } }
      )
      .then(postResponse => {
        const sampleRequest = postResponse.data as SampleRequest; 
        dispatch(updateSampleStandardsVerificationRquestsSuccessAction(sampleRequest));

      }) 
      .catch(error => {
        // console.log("ACTION ERROR!!!!!!!!!!!!", error);
        dispatch(ajaxCallError(error));
        throw error;
      });
  };

};

// export const resetStandardVerificationUnitsAction = (
//   SVUnitDetails: []
// ): ResetStandardVerificationUnitsSuccessAction => ({
//   SVUnitDetails,
//   type: action_types.RESET_STANDARDS_VERIFICATION_UNITS_SUCCESS
// });

// export const resetStandardVerificationUnits = 
// ():ThunkResult<void> =>{
//   return dispatch => {
//     dispatch(resetStandardVerificationUnitsAction([]))
//   }
// }
