import axios, { AxiosRequestConfig } from 'axios';
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  useTokens?: boolean;
}

const uuid = require('uuid/v4');
import AuthForgeRock from './actions/auth/auth';
const auth = new AuthForgeRock();

export const requireTokens = () => {
  axios.interceptors.request.use((config:CustomAxiosRequestConfig) => {
    if(!('useTokens' in config) && config.headers && config.headers.common){
      config.headers.common.Authorization = `Bearer ${auth.getAccessToken()}`;
      config.headers.common["x-id-token"] = `${auth.getIdToken()}`;
    }
    return config;
  });
}

export const requireCorrelationId = () => {
    axios.interceptors.request.use((config:AxiosRequestConfig) => {                
        if (config.headers && config.headers.common) config.headers.common["x-correlation-id"] = uuid();        
        return config;
    });
}

export const requireSessionId = () => {
    axios.interceptors.request.use((config: AxiosRequestConfig) => {
      if(localStorage.getItem("x-session-id") === null){
        localStorage.setItem("x-session-id", uuid())
      }
      if (config.headers && config.headers.common) config.headers.common["x-session-id"] = localStorage.getItem("x-session-id") || "";
      return config;
    });
  };