import * as React from 'react';
import { Modal, ModalHeader, Feedback, ModalBody } from 'ukas.uux.react-components';

require("@webcomponents/webcomponentsjs/webcomponents-bundle");
require("@webcomponents/webcomponentsjs/custom-elements-es5-adapter");

require("@pearson-ux/alert/js/dist/pearson-alert")

interface ErrorAlertProps {
  showAlert: boolean;
  alertTitle: string;
  children?: JSX.Element[] | JSX.Element
  onClose: () => void;
}
const ErrorAlert = ({
    showAlert = false,
    alertTitle,
    children,
    onClose
}: ErrorAlertProps) => {
    if (showAlert) {

        // listener for pearson alert closing to reset state
        window.addEventListener('dismiss', () => {
            onClose();
        }, true);

        return (
            <Modal
                onHide={onClose}
                size="sm"
                show={showAlert}
                centered
            >
                <ModalHeader closeButton>
                        <Feedback variant='danger'>{alertTitle}</Feedback>
                </ModalHeader>
                {children && 
                    <ModalBody>{children}</ModalBody>
                }
            </Modal>
        );
    }

    return <div>&nbsp;</div>;
};

export default ErrorAlert;
