import axios, { AxiosRequestConfig } from 'axios';
// import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

// import * as action_types from '../../../actions/action-types';
import {
  ajaxCallError,
  beginAjaxCall,
  AjaxCallActions
} from '../../../../actions/ajax-status.actions';

import { 
  updateSampleStandardsVerificationRquestsSuccessAction, 
  SampleStandardsVerificationRequestsAction 
} from "../../../shared/sample-standards-verification-request.actions";

import config from '../../../../app-config';
import { StoreState } from '../../../../store/store-state';
import { SampleRequest } from '../../../../models/sample-request';


export type SVLearnerDetailsAction =
  | SampleStandardsVerificationRequestsAction
  | AjaxCallActions;

type ThunkResult<R> = ThunkAction<
  R,
  StoreState,
  undefined,
  SVLearnerDetailsAction
>;



export const submitSampleToLearningProvider = (recallSampleObj: object): ThunkResult<Promise<void>> => {
  return dispatch => {
    dispatch(beginAjaxCall());
    const axiosConfig: AxiosRequestConfig = {
      headers: { 
        "content-type": "application/json"
      }
    };
    return axios
      .post(`${config.API_GATEWAY.URL}/learner-selection/submit-sample-request`,
        JSON.stringify(recallSampleObj), axiosConfig)
      .then(postResponse => {
        const sampleRequest = postResponse.data as SampleRequest;
        
        dispatch(updateSampleStandardsVerificationRquestsSuccessAction(sampleRequest));

      })
      .catch(error => {
        dispatch(ajaxCallError(error)); 
        throw error;
      });
  }
};

export const recallSampleRequestAction = (recallSampleObj: object): ThunkResult<Promise<void>> => {
  return dispatch => {
    dispatch(beginAjaxCall());
    const axiosConfig: AxiosRequestConfig = {
      headers: { 
        "content-type": "application/json"
      }
    };
    return axios
      .post(`${config.API_GATEWAY.URL}/learner-selection/recall-sample-request`,
         JSON.stringify(recallSampleObj), axiosConfig)
      .then(response => {
          const sampleRequest = response.data as SampleRequest; 
  
          dispatch(updateSampleStandardsVerificationRquestsSuccessAction(sampleRequest));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });    
  };
}

export const resetSampleRequestAction = (resetSampleObj: object): ThunkResult<Promise<void>> => {
  return dispatch => {
    dispatch(beginAjaxCall());
    const axiosConfig: AxiosRequestConfig = {
      headers: { 
        "content-type": "application/json"
      }
    };
    return axios
      .post(`${config.API_GATEWAY.URL}/learner-selection/reset-sample-request`,
         JSON.stringify(resetSampleObj), axiosConfig)
      .then(reponseData => {
          const sampleRequest = reponseData.data as SampleRequest; 
  
          dispatch(updateSampleStandardsVerificationRquestsSuccessAction(sampleRequest));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });    
  };
}


