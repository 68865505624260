import * as React from "react";
import  Learner  from '../../../models/learner';
import { statusEnums } from "../../shared/enums";
import { Checkbox } from "ukas.uux.react-components";
import { useUserLoginState } from "../../../shared/context-api/hooks";
import { HasWriteAccess } from "../../../components/common/user-validation";


interface LearnerSelectionListRowProps {
    LearnerSelectionList: Learner;
    singleSampleStandardsVerificationRequest : object;
    onSelect: (LearnerId:string,Learner:Learner,singlesampleRequest:object, event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    selectedLearners: string[];
    display:boolean;
    readOnly: boolean;
    isConsortia: boolean;
    isTeamMember : boolean;
}
const LearnerSelectionListRow = ({
    LearnerSelectionList,
    singleSampleStandardsVerificationRequest,
    onSelect,
    selectedLearners,
  display,
    // learnerIds
  readOnly,
  isConsortia,
  isTeamMember
}: LearnerSelectionListRowProps) => {
  let prevChecked: boolean =
    selectedLearners.indexOf(LearnerSelectionList.learnerId + "") === -1
      ? false
      : true;

  //readOnly will be set if sv reuest status is sample submitted
  //if readonly, show only the selected learners
  if (readOnly && !prevChecked) {
    return null;
  }
  const userLoginstate = useUserLoginState(); 
  return (
    <tr
      className={prevChecked && !readOnly ? "selected" : ""}
      onClick={e => {  !readOnly ?      
        onSelect(
          LearnerSelectionList.learnerId + "",
          LearnerSelectionList,
          singleSampleStandardsVerificationRequest,
          e
        ):'';
      }}
    >
      {/* {isConsortia &&(<td>{LearnerSelectionList.learningProviderCode}</td>)}
      {isConsortia &&(<td>{LearnerSelectionList.learningProviderName}</td>)} */}
      <td>{LearnerSelectionList.learningProviderCode}</td>
      <td>{LearnerSelectionList.learningProviderName}</td>
      <td>{LearnerSelectionList.code}</td>
      <td>
        {LearnerSelectionList.firstName}
        &nbsp;{LearnerSelectionList.surname}
      </td>
      {/* <td>{
        (LearnerSelectionList.Gender == 1)?"Male": "Female"
        }</td>*/}
      {
        (!readOnly && !isTeamMember) && (
      <td> 
        <div className="pe-checkbox">
        <Checkbox
              checked={prevChecked}
              id={LearnerSelectionList.learnerId}
              disabled ={ status === statusEnums.REQUEST_COMPLETED || !HasWriteAccess(userLoginstate)}
              aria-label="this is an aria-label"
            />
        </div>
      </td>
      )}
    </tr>
  );
};

export default LearnerSelectionListRow;
