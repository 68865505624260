import axios from "axios";
import config from "../app-config";

export const auditUserLogin = ():Promise<any> => {

    const axiosConfig = {
      headers: {
        "content-type": "application/json"
      }
    };
      return axios
      .post(
        `${config.API_GATEWAY.URL}/user-audit/login`,
        axiosConfig
      )
      .catch((error: any) => {
        console.log("Audit User Login error", error)
        return error
      });
  }


export const auditUserLogout = ():Promise<any> => {

  const axiosConfig = {
    headers: {
      "content-type": "application/json"
    }
  };
  return axios
    .post(
      `${config.API_GATEWAY.URL}/user-audit/logout`,
      axiosConfig
    )
    .catch((error: any) => {
      console.log("Audit User Logout error", error)
      return error
    });
}