import * as React from "react";
import * as _ from 'lodash';

import LinkLearnersListRow from "./link-learners-list-row";
import Unit, { SelectedUnit } from "../../../models/unit";
import TextHelper from "../../shared/text-formatting";
import { statusEnums } from "../../shared/enums";
import {Button,Table} from 'ukas.uux.react-components';
// const PearsonElementsSDK = require("@pearson-components/elements-sdk/build/dist.elements-sdk");
import { useUserLoginState } from "../../../shared/context-api/hooks";
import { HasWriteAccess } from "../../../components/common/user-validation";

interface LinkLearnersListProps {
  onSort: (item: string) => void;
  getIconName: (columnName: string) => string;
  goBack: (event: any) => void;
  onSubmit: (event: any) => void;
  onRecall: (event: any) => void;
  onReset: (event: any) => void;
  isDisabled: boolean;
  units: UnitWithLearners[];
  sampleRequestStatus: string;
  showLearners: (unitId: string, unitName: string,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  showReset: boolean;
  isTeamMember: boolean;
}

export type UnitWithLearners = SelectedUnit & Unit;

const LinkLearnersList = ({
  onSort,
  getIconName,
  goBack,
  onSubmit,
  onRecall,
  onReset,
  isDisabled,
  units,
  sampleRequestStatus,
  showLearners,
  showReset,
  isTeamMember
}: LinkLearnersListProps) => {
  const sampleReqStatusText =  TextHelper.getStatusText(sampleRequestStatus);
  const userLoginstate = useUserLoginState();
  return (
    <div>
      <p>&nbsp;</p>
      {/* <table className="pe-table pe-table--active-headers pe-table--selectable pe-table--sortable"> */}
      <Table>
        <thead>
          <tr>
            <td>Unit</td>
            <td>No. of linked learners</td>
            {/* <th>No. of linked learners</th> */}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {units.map((unit: UnitWithLearners) => {
            if (unit) {
              return (<LinkLearnersListRow
                key={unit.unitId}
                unit={unit}
                selectedLearners={unit.selectedLearners}
                showLearners={showLearners}
              />);
            } else {
              return;
            }

          })
          }
        </tbody>
      {/* </table> */}
      </Table>

      <Button onClick={(e)=>goBack(e)}>Back</Button>

      {sampleReqStatusText === statusEnums.REQUEST_COMPLETED ? '' :
        <div className="pull-right">
        { sampleReqStatusText === statusEnums.REQUEST_SUBMITTED  && 
        <Button 
        onClick={(e)=>onRecall(e)}
        variant="primary"  
          disabled={ isTeamMember || !HasWriteAccess(userLoginstate) ? true : false}        
        >
        Recall Request         
        </Button>
        }
        &nbsp;&nbsp;
        <Button
          onClick={(e)=>onSubmit(e)}
          variant="primary"
          disabled={(isDisabled || isTeamMember || !HasWriteAccess(userLoginstate)) ? true : false}
        >
          Submit
        </Button>

        { sampleReqStatusText === statusEnums.REQUEST_RECALLED && showReset &&
          <span>&nbsp;&nbsp;
            <Button 
              onClick={(e)=>onReset(e)}         
              variant="primary"  
              disabled={ isTeamMember || !HasWriteAccess(userLoginstate) ? true : false}        
            >
              Reset
            </Button>
          </span>
        }
      </div>
}
      <p>&nbsp;</p>
    </div>
  )
};

export default LinkLearnersList;
