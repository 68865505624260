import { StoreState } from '../store/store-state';
import AuthForgeRock from '../actions/auth/auth';

const authForgeRock = new AuthForgeRock();

export const InitialState: StoreState = {
    assessmentAssociate: null,
    hasGlobalError: false,
    ajaxCallsInProgress: 0,
    SVUnitSelectionList: [],   
    sampleRequests: [],   
    sampleMediaRequests: [],
    isGetSampleMediaRequestCompleted: false,
    isGetSampleMediaRequestStarted: false,
    SVLearnerSelectionList: [], 
    auth: {
        error: '',
        timestamp: 0,
        loading: false,
        authenticated: authForgeRock.isAuthenticated(),
        profile: authForgeRock.getProfile()
    },
    userDetails:{
        aaNumber: '',
        associatedLearningProviders:[],
        isUserPermissionValid: true,
        lpExclusions : [],
        userPermissions:{
            lwaReadAccess: true,
            lwaWriteAccess: true
          }
    },
    showCreateMenu : false,
    userPermissionValidated:true,   
    callback: {
        error: '', 
        loading: false 
    },
    isAATeamLead: false,
    selectedAATeamName:'',
    aaTeams: [],
    globalAlertMessages: [],
    proxyAATeamMember: {
        teamName:'',
        firstName: '',
        lastName: '',
        assessmentAssociateId: 0,
        aaNumber: ''
    }
}

export default InitialState; 