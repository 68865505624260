import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import App from './components/app.container';
import { ThemeProvider } from "styled-components";
import { theme } from "ukas.uux.react-components";
import './index.css';
import configureStore from './store/configureStore'; 
import { InitialState } from './reducers/initialState';
import { requireCorrelationId, requireSessionId, requireTokens } from './axios-interceptors';
import AuthForgeRock from './actions/auth/auth';
const auth = new AuthForgeRock();

const store = configureStore(InitialState);

requireTokens();
requireCorrelationId();
requireSessionId();

import * as serviceWorker from './serviceWorker';

function startApp() {
  ReactDOM.render(
    <StrictMode>
      <Provider store={store}>
        <IntlProvider locale={'en-GB'}>
        <ThemeProvider theme={theme}>
          <App />
          </ThemeProvider> 
        </IntlProvider>
      </Provider>
      </StrictMode>,
      document.getElementById('root') as HTMLElement
  );
}

const idTokenDecrypted = localStorage.getItem("payload");
if(window.location.pathname === "/logout"){
  auth.signout();
}

if(window.location.pathname === "/callback" && 
  window.location.search.indexOf("code") > -1) 
  {
    startApp();
} else {
  if(!idTokenDecrypted) {
    auth.signin();
  } else {
    startApp();
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
