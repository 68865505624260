// import * as auth0 from 'auth0-js';
const jso = require('jso');
import jwt_decode from 'jwt-decode';
import cryptoBrowserify from "crypto-browserify";
// import * as Cookies from 'js-cookie';
import { params } from './auth-params';
import moment from 'moment';

import axios, { AxiosRequestConfig } from 'axios';
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  useTokens?: boolean;
}
export default class AuthForgeRock {
  JSO_CLIENT = new jso.JSO(params);

  constructor() {
    this.base64URLEncode = this.base64URLEncode.bind(this);
    this.sha256 = this.sha256.bind(this);
    this.getNewVerifier = this.getNewVerifier.bind(this);
    this.signin = this.signin.bind(this);
    this.fetchToken = this.fetchToken.bind(this);
    this.setExpiryTime = this.setExpiryTime.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getProxyName = this.getProxyName.bind(this);
    this.getProxyId = this.getProxyId.bind(this);
  }

  base64URLEncode = (str: any) => {
    return str
      .toString("base64")
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");
  };
  
  sha256 = (buffer: any) => {
    return cryptoBrowserify.createHash("sha256").update(buffer).digest();
  };

  getNewVerifier = () => {
    const newVerifier = this.base64URLEncode(cryptoBrowserify.randomBytes(32));
    window.sessionStorage.setItem("verifier", newVerifier);
    const challenge = this.base64URLEncode(this.sha256(newVerifier));
    const config = params;
    config.request.code_challenge = challenge;
    this.JSO_CLIENT.configure(params);
    return challenge;
  };

  signin() {
    const challenge = this.getNewVerifier();
    window.location.replace(
      `${params.baseUrl}/oauth2/authorize?scope=${params.scopes.request.join('+')}&response_type=code&client_id=${params.client_id}&code_challenge=${challenge}&code_challenge_method=S256&redirect_uri=${params.redirect_uri}`
    );
  }

  injectInternalFlag(profile: any) {
    const dummyInternalUsers=[
      { email:'LP.Sixteen@mail.com', family_name: 'Internal' },
      { email:'LP.Twenty@mail.com', family_name: 'Internal' },
      { email:'SV.Nine@mail.com', family_name: 'Internal' },
      { email:'SV.Eleven@mail.com', family_name: 'Internal' },
    ];
    let userMatch = {};
    if(profile && profile.email)
    {
      const loggedInUserEmail = profile.email.toLowerCase();      
      userMatch = dummyInternalUsers.filter(u=>u.email.toLowerCase()===loggedInUserEmail)[0] 
      // if(!userMatch) {
      //   userMatch = {profile:"000", family_name:"NON"}
      // }
    }
    return userMatch;
  }

  parseDummyUsers (profile:any) {
    const dummyUsers=[
         { email:'LP.Twenty@mail.com', profile:'620', family_name: 'Internal' },
      { email:'LP.Nineteen@mail.com', profile:'619', family_name: 'External' },
      { email:'LP.Eighteen@mail.com', profile:'618', family_name: 'External' },
      { email:'LP.Seventeen@mail.com', profile:'617', family_name: 'External' },
      { email:'LP.Sixteen@mail.com', profile:'616', family_name: 'Internal' },
      { email:'LP.Fifteen@mail.com', profile:'615', family_name: 'External' },
      { email:'LP.Fourteen@mail.com', profile:'614', family_name: 'External' },
      { email:'LP.Thirteen@mail.com', profile:'613', family_name: 'External' },
      { email:'LP.Twelve@mail.com', profile:'612', family_name: 'External' },
      { email:'LP.Eleven@mail.com', profile:'611', family_name: 'External' },
      { email:'LP.Ten@mail.com', profile:'610', family_name: 'External' },
      { email:'LP.Nine@mail.com', profile:'609', family_name: 'External' },
      { email:'LP.Eight@mail.com', profile:'608', family_name: 'External' },
      { email:'LP.Seven@mail.com', profile:'607', family_name: 'External' },
      { email:'LP.Six@mail.com', profile:'606', family_name: 'External' },
      { email:'LP.Five@mail.com', profile:'605', family_name: 'External' },
      { email:'LP.Four@mail.com', profile:'604', family_name: 'External' },
      { email:'LP.Three@mail.com', profile:'603', family_name: 'External' },
      { email:'LP.Two@mail.com', profile:'602', family_name: 'External' },
      { email:'LP.One@mail.com', profile:'601', family_name: 'External' },
      { email:'SV.Eleven@mail.com', profile:'711', family_name: 'Internal' },
      { email:'SV.Ten@mail.com', profile:'710', family_name: 'External' },
      { email:'SV.Nine@mail.com', profile:'709', family_name: 'Internal' },
      { email:'SV.Eight@mail.com', profile:'708', family_name: 'External' },
      { email:'SV.Seven@mail.com', profile:'707', family_name: 'External' },
      { email:'SV.Six@mail.com', profile:'706', family_name: 'External' },
      { email:'SV.Five@mail.com', profile:'705', family_name: 'External' },
      { email:'SV.Four@mail.com', profile:'704', family_name: 'External' },
      { email:'SV.Three@mail.com', profile:'703', family_name: 'External' },
      { email:'SV.Two@mail.com', profile:'702', family_name: 'External' },
      { email:'SV.One@mail.com', profile:'701', family_name: 'External' },

    ];
    let userMatch = {};
    if(profile && profile.email)
    {
      const loggedInUserEmail = profile.email.toLowerCase();      
      userMatch = dummyUsers.filter(u=>u.email.toLowerCase()===loggedInUserEmail)[0] 
      if(!userMatch) {
        userMatch = {profile:"000", family_name:"NON"}
      }
    }
    return userMatch;
  }

  fetchToken = (code: string): Promise<any> => {
    const verifier = window.sessionStorage.getItem("verifier");
    const body = `grant_type=authorization_code&client_id=${params.client_id}&code_verifier=${verifier}&code=${code}&redirect_uri=${params.redirect_uri}`;
    return new Promise((resolve, reject) => {
      axios
        .post(params.token, body, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          useTokens: false
        } as CustomAxiosRequestConfig)
        .then((response: any) => {
          window.sessionStorage.removeItem("verifier");
          const {
            access_token,
            id_token,
            refresh_token,
            expires_in,
          } = response.data;
          window.localStorage.setItem("accessToken", access_token);
          window.localStorage.setItem("idToken", id_token);
          window.localStorage.setItem("refreshToken", refresh_token);
          let decoded = jwt_decode(id_token);
          localStorage.setItem("payload", JSON.stringify(decoded));
          // console.log("profileDataSet", decoded);
          this.setExpiryTime(expires_in);
          const checkYear = moment();
          const month:string = checkYear.format('M');
          let getYear = checkYear.format('YYYY');
          if(parseInt(month) < 9){
            const nextyear = (moment().subtract(1, 'years'));
            getYear = nextyear.format('YYYY');   
          }
          localStorage.setItem('selectedYear', getYear);
          resolve(decoded);
        });
    });
  };

  setExpiryTime = (expires_in: number) => {
    const expiresAt = new Date().getTime() + (expires_in - 300) * 1000;
    window.localStorage.setItem("tokenExpires", expiresAt.toString());
  };

  baseUrl = params.baseUrl;
  clientId = params.client_id;
  redirectUri = params.redirect_uri;
  tokenUrl = params.token;

  refreshTokens = async (refreshToken: any) => {
    const body = `grant_type=refresh_token&client_id=${this.clientId}&refresh_token=${refreshToken}`;
    return axios
      .post(this.tokenUrl, body, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((response: any) => {
        const { access_token, id_token, expires_in } = response.data;
        window.localStorage.setItem("accessToken", access_token);
        window.localStorage.setItem("idToken", id_token);
        this.setExpiryTime(expires_in);
      });
  };


  handleAuthentication = () => {
   return new Promise<void>((resolve, reject) => {
    const callbackURL = new URL(window.location.href);
    if (callbackURL) {
      const authCode = callbackURL.searchParams.get("code");
      if (authCode) {
    
            if (this.isAuthenticated()) {
                    resolve();
                    } else {
                      const refresh_token = localStorage.getItem("refreshToken");
                      if (refresh_token)
                        this.refreshTokens(refresh_token)
                          .then((response) => { 
                         resolve();
                          })
                          .catch(() => {
                    
                          reject({});
                          });
                      else {
                        this.signin();
                      }
                    }
      }
    
        }
      })
    }


  getProfile() {
    const profile = localStorage.getItem('payload');
    if (!profile) {
      return new Error('No profile found');
    }

    return JSON.parse(profile);
  }

  getProxyName(){
    const proxyName = localStorage.getItem('proxyName');
    return proxyName?proxyName: '';
  }

  getProxyId(){
    const proxyId = localStorage.getItem('prevProxyId');
    return proxyId?proxyId: '';
  }
  
  isAuthenticated() {
    const expiresAt = localStorage.getItem("tokenExpires");
    if (expiresAt) return new Date().getTime() < parseInt(expiresAt);
    return false;
  }

  signout() {
    let endSessionEndpoint = window.location.origin;
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace(
      `${params.sessionEndEndpoint}?client_id=${params.client_id}&redirect_uri=${endSessionEndpoint}/callback&response_type=code`
    );
  }

  getAccessToken() {
    return window.localStorage.getItem("accessToken");
  }

  getIdToken() {
    return window.localStorage.getItem("idToken");
  }
}