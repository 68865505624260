import axios, { AxiosRequestConfig } from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { MediaRequest } from '../../../../models/media-request';
import * as action_types from '../../../../actions/action-types';
import {
  ajaxCallError,
  beginAjaxCall,
  AjaxCallActions
} from '../../../../actions/ajax-status.actions';

import config from '../../../../app-config';
import { StoreState } from '../../../../store/store-state';
import { SampleRequest } from '../../../../models/sample-request';


import { 
  updateSampleStandardsVerificationRquestsSuccessAction
} from "../../../shared/sample-standards-verification-request.actions";


interface GetSampleMediaRequestsSuccessAction extends Action {
  type: action_types.GET_LEARNER_MEDIA_REQUESTS_SUCCESS;
  sampleMediaRequests: MediaRequest[];
}

interface UpdateSampleStandardsVerificationRequestsSuccessAction extends Action {
  type: action_types.UPDATE_SAMPLE_STANDARDS_VERIFICATION_REQUESTS_SUCCESS;
  sampleRequest: SampleRequest;
}

export type SampleMediaRequestsAction =
  | UpdateSampleStandardsVerificationRequestsSuccessAction
  | GetSampleMediaRequestsSuccessAction
  | AjaxCallActions;

 

type ThunkResult<R> = ThunkAction<
  R,
  StoreState,
  undefined,
  SampleMediaRequestsAction
>;

export const getLearnerMediaSuccessAction = (
  sampleMediaRequests: MediaRequest[]
): GetSampleMediaRequestsSuccessAction => ({
  sampleMediaRequests,
  type: action_types.GET_LEARNER_MEDIA_REQUESTS_SUCCESS
});

export const getLearnerMediaRequests = (
    learningProviderId: string,
    mediaSetId: string
  ): ThunkResult<Promise<void>> => {
    return dispatch => {
      dispatch(beginAjaxCall());
      // console.log("ACTION-ALM--",learningProviderId+'/'+mediaSetId);
      return axios
        .get(`${config.API_GATEWAY.URL}/access-learner-media/${learningProviderId+'/'+mediaSetId}`, {        
          params: {
            // learningProviderId,
            // mediaSetId
          }
        })
        .then(response => {
          const sampleMediaRequests = [response.data];
         
          dispatch(
            getLearnerMediaSuccessAction(
              sampleMediaRequests.map((sampleMediaRequest: MediaRequest) => {
                const mr = new MediaRequest();
                mr.mediaSetId = sampleMediaRequest.mediaSetId;
                mr.qualificationId = sampleMediaRequest.qualificationId;
                mr.qualificationName = sampleMediaRequest.qualificationName;
                mr.learningProviderId = sampleMediaRequest.learningProviderId;
                mr.learningProviderCode = sampleMediaRequest.learningProviderCode;
                mr.learningProviderName = sampleMediaRequest.learningProviderName;
                mr.deadlineDateTime = new Date(sampleMediaRequest.deadlineDateTime);
                mr.numberOfLearners = sampleMediaRequest.numberOfLearners;
                mr.numberOfFiles = sampleMediaRequest.numberOfFiles;
                mr.standardsVerifierId = sampleMediaRequest.standardsVerifierId;
                mr.standardsVerifierName = sampleMediaRequest.standardsVerifierName;
                mr.learnerUnits = sampleMediaRequest.learnerUnits;
                mr.files = sampleMediaRequest.files;
                mr.status = sampleMediaRequest.status;
                mr.submissionDate = new Date(sampleMediaRequest.submissionDate);                
                mr.submittedBy = sampleMediaRequest.submittedBy;
                // console.log("AFTER ACTION>>>",mr)
                return mr;
              })
            )
          );
        })
        .catch(error => {
          dispatch(ajaxCallError(error));
          throw error;
        });
    };
  };

  /** Reject Sample Action */
  export const rejectSampleRequestAction = (
    assessmentAssociateId: string, 
    standardsVerificationSampleId: string
  ): ThunkResult<Promise<void>> => {
    const rejectSampleObj={assessmentAssociateId, standardsVerificationSampleId}
    return dispatch => {
      dispatch(beginAjaxCall());
      const axiosConfig: AxiosRequestConfig = {
        headers: { 
          "content-type": "application/json"
        }
      };
      return axios
        .post(`${config.API_GATEWAY.URL}/sample-standards-verification-requests/reject-sample-request`,
          JSON.stringify(rejectSampleObj), axiosConfig)
        .then(postResponse => {
          const sampleRequest = postResponse.data as SampleRequest;
          
          dispatch(updateSampleStandardsVerificationRquestsSuccessAction(sampleRequest));
  
        })
        .catch(error => {
          dispatch(ajaxCallError(error)); 
          throw error;
        });
    }
  };
  