import React, { useEffect } from "react";

import { useHistory } from "react-router";
import { useUserLoginDispatch } from "../../shared/context-api/hooks";
import { userLoggedIn } from "../../shared/context-api/actions";
import Auth from "../../actions/auth/auth";
import loading from './loading.svg';

const auth = new Auth();

export interface DispatchProps {
  handleAuthentication: (callback: (err: string) => void) => void;
  callbackInProgress: () => void;
  signoutUser: () => void;
  callbackComplete: () => void;
}

export interface CallbackProps {
  loading: boolean,
  error: string
}

type Props = CallbackProps & DispatchProps;

const CallBack = (props: Props) => {
  const history = useHistory();
  const userLoginDataDispatch = useUserLoginDispatch();

  useEffect(() => {

    if (
      window.location.protocol === "https:" ||
      window.location.protocol === "http:"
    ) {
      const callbackURL = new URL(window.location.href);
      if (callbackURL) {
        const authCode = callbackURL.searchParams.get("code");
        if (authCode) {
          auth.fetchToken(authCode)
            .then((decodedToken: any) => {
              userLoginDataDispatch(
                userLoggedIn({
                  cognitoPearsonUID: decodedToken["custom:pearsonUID"],
                })
              );
              props.handleAuthentication(err => {
                if (err) {
                  history.push('/');
                }
                else {
                  props.callbackComplete();
                  return history.push('/Home');
                }
                history.push("/");
              })

            })

            .catch((err) => {
              console.log("err", err)
            });

  
        }
      }
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);
  
  const style = {
    backgroundColor: 'white',
    bottom: 0,
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    left: 0,
    position: 'absolute' as 'absolute',
    right: 0,
    top: 0,
    width: '100vw'
  };

  return <React.Fragment>  
      <div style={style}>  
     <img src={loading} alt="loading" style={{width:"142px"}}/>
     </div> 
     </React.Fragment>;
};

export default CallBack;