import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Route, Redirect, Switch, RouteComponentProps, RouteProps } from 'react-router';
import breadCrumbImage from "../breadcrumb-18.svg";

import withBreadcrumbs, {
  BreadcrumbsRoute,
  InjectedProps,

} from 'react-router-breadcrumbs-hoc';

import Home from './home/home';
import NotFound from './not-found/not-found';
// import Maintain from '../components/common/maintain/maintain'
import Callback from './callback/callback.container';
import CreateSampleRequest from '../features/sample-requests/create-sample-request/create-sample-request.container.component';
import AuthForgeRock from '../actions/auth/auth';

import SampleStandardsVerificationRequests from '../features/sample-requests/sample-standards-verification-request-list/sample-standards-verification-request-list.container.component';
import UnitSelection from '../features/sample-requests/unit-selection/unit-selection-list.container.component';
import AccessLearnerMaterial from '../features/sample-requests/access-learner-material/access-learner-material.container.component';
import LinkLearners from '../features/sample-requests/link-learners/link-learners.container.component';

const auth = new AuthForgeRock();

// const PearsonElementsSDK = require('@pearson-components/elements-sdk/build/dist.elements-sdk');

const routes: BreadcrumbsRoute[] = [
  { path: '/not-found', breadcrumb: '404' },
  // { path: '/maintenance', breadcrumb: '' },
  // { path: '/create-sample-request', breadcrumb: 'Create Sample Request' },
  { path: "/", breadcrumb: "Home" },
  { path: '/sample-requests', breadcrumb: 'Sample Requests' },
  { path: '/sample-requests/access-learner-material/:allocationId', breadcrumb: 'Access Learner Material' },
  { path: '/sample-requests/unit-selection/:allocationId', breadcrumb: 'Unit Selection' },
  { path: '/sample-requests/unit-selection/:allocationId/link-learners', breadcrumb: 'Link Learners' },
  { path: '/sample-requests/link-learners/:allocationId', breadcrumb: 'Link Learners' }  
];

// map & render your breadcrumb components however you want
const Breadcrumbs = ({ breadcrumbs }: InjectedProps) => (
  <div className="breadcrumb-group" style={{ paddingTop: 20 }}>
    {breadcrumbs.map(({ breadcrumb, match }, index: number) => (
      <span key={match.url}>
        {breadcrumbs.length - 1 !== index ? (
          <span key={match.url} className="breadcrumb">
            <NavLink
              to={match.url}
              className="pe-label pe-label--small"
            >{breadcrumb}
            </NavLink>
            <img src={breadCrumbImage} className="breadCrumbImage"/>
            {/* <PearsonElementsSDK.Icon name="breadcrumb-18" /> */}
          </span>
        ) : (
            <span className="pe-label--small pe-bold">{breadcrumb}</span>
          )}
      </span>
    ))}
  </div>
);
export const Routes = () => (
  <div>
    <Switch>
      <Route exact={true} path="/not-found" component={NotFound} />
      {/* <Route exact={true} path="/maintenance" component={Maintain} /> */}
     
      <Route exact={true} path="/" component={Home} />
      <PrivateRoute
        exact={true}
        path="/create-sample-request"
        component={CreateSampleRequest} />
      <PrivateRoute
        exact={true}
        path="/sample-requests"
        component={SampleStandardsVerificationRequests}
      />
      <PrivateRoute
        exact={true}
        path="/sample-requests/access-learner-material/:allocationId"
        component={AccessLearnerMaterial}
      />
      <PrivateRoute 
        exact={true} 
        path="/sample-requests/unit-selection/:allocationId" 
        component={UnitSelection}
      />
      <PrivateRoute
        exact={true}
        path="/sample-requests/unit-selection/:allocationId/link-learners"
        component={LinkLearners}
      />
      <Route
        exact={true}
        path="/select-an-aa"
        component={SampleStandardsVerificationRequests}
      />
      <PrivateRoute
        exact={true}
        path="/sample-requests/link-learners/:allocationId"
        component={LinkLearners}
      />
      <Route exact={true} path="/callback" component={Callback} />
      <Route exact={true} path="/home">
        <Redirect to={{ pathname: '/' }} />
      </Route>
      <Redirect to="/not-found" /></Switch>
  </div>
);

export const x = withBreadcrumbs(routes, { disableDefaults: true })(Breadcrumbs);

const PrivateRoute = ({ component, ...rest }: RouteProps) => {
  if (!component) {
    throw Error('component is undefined');
  }

  const Component = component; // JSX Elements have to be uppercase.

  const render = (props: RouteComponentProps<{}>): React.ReactNode => {
    if (auth.isAuthenticated()) {
      return <Component {...props} />;
    }
    return <Redirect to={{ pathname: '/' }} />;
  };

  return <Route {...rest} render={render} />;
};

export default Routes;
export { x as breadcrumb };
